import React from "react";
import "./style.css";
import { Button } from "@material-ui/core";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";

function CartProduct(props) {
  return (
    <div className="cartProduct">
      <div className="cartProduct__container">
        <div className="cartProduct__product">
          <div className="cartProduct__img">
            <img src={props.item.media.source} alt="" />
          </div>
          <div className="cartProduct__detail">
            <p className="cartProduct__title">
              {props.item.name.length > 50
                ? props.item.name.slice(0, 35) + "..."
                : props.item.name}
            </p>
            <p className="cartProduct__price">
              Rs. {props.item.price.raw.toLocaleString()}
            </p>
          </div>
        </div>
        <div className="cartProduct__qty">x {props.item.quantity}</div>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            props.removeFromCartHandle(props.item.id);
          }}
        >
          <RemoveCircleRoundedIcon fontSize="small" />
          <span className="cartProduct__btn">Remove</span>
        </Button>
      </div>
    </div>
  );
}

export default CartProduct;
