import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import "./style.css";

function CodefumesIcon(props) {
  return (
    <span className="codefumesIcon">
      <SvgIcon
        {...props}
        viewBox="0 0 48.04 64.5"
        className="codefumesIcon__svgIcon"
      >
        <path
          className="cls-1"
          d="M163.05,178.89s-3.92-.92-3.69,5.77L159.13,195l15.45,27.21s4.61,10.61-6.92,11.76H136.28c-11.53-1.15-6.92-11.76-6.92-11.76L144.81,195l-.23-10.38c.23-6.69-3.69-5.77-3.69-5.77"
          transform="translate(-127.95 -170.01)"
        />
        <path
          className="cls-2"
          d="M144.71,200.46,132.13,222s-4.5,9.19,5.61,9.87H166.2c10.33-.46,5.61-9.87,5.61-9.87l-12.58-21.49a5.35,5.35,0,0,0-4.38.23c-1.15.69-1.61,0-1.61,0a2.55,2.55,0,0,0-3.69,0,1.91,1.91,0,0,0-2.08,0C145.63,198.84,144.71,200.46,144.71,200.46Z"
          transform="translate(-127.95 -170.01)"
        />
        <text className="cls-3" transform="translate(11.08 53.85)">
          &lt;/&gt;
        </text>
        <path
          className="cls-2"
          d="M151.39,170a9,9,0,0,1-.23,8.08,7.89,7.89,0,0,0-.23,7.61A6,6,0,0,0,153,188a8.58,8.58,0,0,1-.92-3.22,10.21,10.21,0,0,1,1.38-6s2.08-3.69-.23-6.92Z"
          transform="translate(-127.95 -170.01)"
        />
        <path
          className="cls-2"
          d="M149.63,180.16a9,9,0,0,0,1.58,7.92,7.91,7.91,0,0,1,1.51,7.46,6,6,0,0,1-1.65,2.62,8.73,8.73,0,0,0,.36-3.34,10.14,10.14,0,0,0-2.38-5.67s-2.67-3.29-.94-6.86Z"
          transform="translate(-127.95 -170.01)"
        />
        <path
          className="cls-2"
          d="M154.94,180.16a5.36,5.36,0,0,0,.75,5.64,4.72,4.72,0,0,1,.72,5.31,4.58,4.58,0,0,1-1.6,1.79,5.5,5.5,0,0,0,.56-2.34,6.28,6.28,0,0,0-1.59-4.09,4.11,4.11,0,0,1-.27-4.86Z"
          transform="translate(-127.95 -170.01)"
        />
      </SvgIcon>
    </span>
  );
}

export default CodefumesIcon;
