import { Button, Container, Grid} from "@material-ui/core";
import React, { useState } from "react";
import "../style.css";
import { TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { BOOK_PRINTING_COST_LIST } from '../../../constants'

function BookPrinting() {
  const [noOfPages, setNoOfPages] = useState(0);
  const [curPageQuality, setCurPageQuality] = useState(Object.keys(BOOK_PRINTING_COST_LIST)[0]);
  const [curBinding, setCurBinding] = useState(1);

  const calculatePrice = () => {
    let temp = noOfPages * BOOK_PRINTING_COST_LIST[curPageQuality];

    if (curBinding === 1) {
      return temp;
    } else if (curBinding === 2) {
      return temp + 1000;
    }
  };

  return (
    <div className="servicesPage">
      <img
        src="assets/images/banners/services_book-printing.jpg"
        alt="book-printing.jpg"
        className="servicesPage__banner"
      />
      <Container className="servicesPage__description">
        <b style={{ color: "#b30d2b" }}>TheBookDot.Com</b> provides premium
        Quality Service for custom Book Print. We are best book printing, pdf printing service provider in Pakistan. If you didn't find a book or you
        have a pdf of that book we can print that book for you on demand. The
        printed book will be same like originals you can check our samples on
        our Official youtube channel or here also. Both Hardcover and Paperback
        Binding Available. Premium Quality guaranteed.
        <br />
        Can Provide shipment All around the world
        Providing best and cheap printing services in Pakistan and can export to other countries.
      </Container>
      <Container>
        <h1>Pricing (PKR)</h1>
        <br />
        <table
          style={{ width: "100%", textAlign: "center", fontSize: "1.1em" }}
          >
          <tr>
            <th>Paper Quality</th>
            <th>Price Per Page</th>
            <th>Digital Title Print</th>
            <th>Gum Binding</th>
          </tr>
          {Object.keys(BOOK_PRINTING_COST_LIST).map((item, index) => (
            <tr>
              <td>{item}</td>
              <td>Rs. {BOOK_PRINTING_COST_LIST[item]}</td>
              <td>Rs. 250 (laminated)</td>
              <td>Rs. 250</td>
            </tr>
          ))}
        </table>
        <br />
        <br />
      </Container>
      <Container className="servicesPage__getButtonContainer">
        <h2>ARE YOU LOOKING FOR A QUALITY BOOK PRINTING SERVICE?</h2>
        <p>
          Now you can calculate the pricing specified to the book that you want
          to get printed using the calculator below.
        </p>
        <br />
        <Grid container spacing={3} className="bookPrinting__calculator">
          <Grid item xs={6} md={3}>
            <TextField
              variant="outlined"
              type="number"
              value={noOfPages}
              onChange={(e) => {
                if (e.target.value >= 0) setNoOfPages(e.target.value);
              }}
              label="No of Pages"
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Page Quality
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={curPageQuality}
                onChange={(e) => {
                  setCurPageQuality(e.target.value);
                }}
                label="Page Quality"
              >
                {Object.keys(BOOK_PRINTING_COST_LIST).map((item, index) => (
                  <MenuItem value={item} key={index}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label2">
                Cover
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label2"
                id="demo-simple-select-outlined2"
                value={curBinding}
                onChange={(e) => {
                  setCurBinding(e.target.value);
                }}
                label="Cover"
              >
                <MenuItem value={1}>Paperback</MenuItem>
                <MenuItem value={2}>Hard Binding</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <h2>
              Price (Rs.): {!noOfPages ? 0 : calculatePrice() + 250 + 250}
            </h2>
            <p style={{ textAlign: "center" }}>
              {noOfPages > 0 && (
                <span>
                  {calculatePrice()}(pages) + 250(title) + 250 (binding)
                </span>
              )}
            </p>
          </Grid>
        </Grid>
        <br />
        <p>Click below to get personlized plan via our representatives.</p>
        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          color="secondary"
          href="http://bit.ly/TBC_contact"
        >
          Get your quote now!
        </Button>
      </Container>
    </div>
  );
}

export default BookPrinting;
