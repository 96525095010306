import React from "react";
import "./style.css";
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Link } from "react-router-dom";

function CartBoxOnPage({ cart, onMouseOver, onMouseLeave }) {
  return (
    <div
      className="cartBoxOnPage"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {cart && cart.subtotal && (
        <div className="cartDetails">
          <h2 className="yourCart">Your Cart</h2>
          <div className="cartItemsContainer">
            {cart.line_items && cart.line_items.length > 0 ? (
              cart.line_items.map((i, ind) => (
                <div
                  className="cartBox__item"
                  style={{
                    borderBottom:
                      ind < cart.line_items.length - 1
                        ? "1px solid lightgray"
                        : "",
                    paddingTop: "12px",
                  }}
                >
                  <img src={i.media.source} alt="" />
                  <div>
                    <p className="heading">{i.name}</p>
                    <small>
                      Price:{" "}
                      <strong className="price">
                        {i.price.formatted_with_code}
                      </strong>
                    </small>
                    <br />
                    <small>
                      Quantity:{" "}
                      <strong className="quantity">{i.quantity}</strong>
                    </small>
                    <br />
                    <br />
                  </div>
                </div>
              ))
            ) : (
              <div className="cartBox__item">
                <h1 style={{ textAlign: "center", marginLeft: "10px" }}>
                  <br />
                  <br />
                  <br />
                  Cart Empty 😐
                </h1>
              </div>
            )}
          </div>
          <div style={{ textAlign: "right" }}>
            Subtotal: <strong>{cart.subtotal.formatted_with_code}</strong>
          </div>
          <Link to="/cart">
            <Button variant="outlined" color="secondary">
              Checkout <ShoppingCartIcon />
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default CartBoxOnPage;
