import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { AlertLine } from "../../components/Header";

function MainLayout(props) {
  return (
    <div className="mainLayout">
      <div>
        {props.alertLine && (
          <AlertLine data={props.alertLine} handler={props.setAlertLine} />
        )}
        <Header {...props} />
        {props.children}
        <Footer {...props} />
      </div>
    </div>
  );
}

export default MainLayout;
