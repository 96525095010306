import { Button, Container } from "@material-ui/core";
import React from "react";
import "../style.css";

function Wholesale() {
  return (
    <div className="servicesPage">
      <img
        src="assets/images/banners/services_wholesale.jpg"
        alt="services_wholesale.jpg"
        className="servicesPage__banner"
      />
      <Container className="servicesPage__description">
        If you are going to start your own new business of selling books and you have enough investment, <b style={{ color: "#b30d2b" }}>TheBookDot.Com</b> is always available to support you by providing books on wholesale rate but you must have to purchase books of minimum order of Rs. 50000. We deliver your books in your desired city. There is no need to worry if you are from urban area of undeveloped city. We have 3 types of quality, Good, Better and Best. All have different rate according to their quality. So Hurry up and Start something big.
      </Container>
      <Container className="servicesPage__getButtonContainer">
        <h2>ARE YOU LOOKING FOR A QUALITY WHOLESALE SUPPLY SERVICE?</h2>
        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          color="secondary"
          href="http://bit.ly/TBC_contact"
        >
          Get your quote now!
        </Button>
      </Container>
    </div>
  );
}

export default Wholesale;
