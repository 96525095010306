import React from "react";
import "./style.css";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import logo from "../../assets/img/logo_long.png";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Drawer from "@material-ui/core/Drawer";
import HeaderMenuItem from "../HeaderMenuItem";
import CartBoxOnPage from "../CartBoxOnPage";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import CONSTANTS from "../../constants";
import SearchIcon from "@material-ui/icons/Search";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Autocomplete } from "@material-ui/lab";
import { ProductAPI } from "../../utils/serverAPI";
import { Link } from "react-router-dom";

function Header(props) {
  const [drawerState, setDrawerState] = React.useState(false);
  const [searchQueryText, setSearchQueryText] = React.useState("");
  const history = useHistory();
  const [allBookNamesArr, setAllBookNamesArr] = React.useState([]);
  const [searchBarFlag, setSearchBarFlag] = React.useState(false);
  const [cartBoxOnPageOpen, setCartBoxOnPageOpen] = React.useState(false);
  const [cartBoxOnPageOpen2, setCartBoxOnPageOpen2] = React.useState(false);

  React.useEffect(() => {
    if (searchBarFlag) {
      ProductAPI.getBySearchQuery()
        .then((res) => {
          if (res.data && res.data.data) {
            setAllBookNamesArr(
              res.data.data.map((p) => ({
                id: p.id,
                title: p.name,
              }))
            );
          } else {
            setAllBookNamesArr([]);
          }
        })
        .catch(() => {
          setAllBookNamesArr([]);
        });
    }
  }, [searchBarFlag]);

  // Toggling drawer
  const toggleDrawer = () => {
    setDrawerState((prevDrawerState) => {
      return !prevDrawerState;
    });
  };

  // Toggling the search bar
  const toggleSearchBar = () => {
    if (searchBarFlag) {
      document.getElementById("header__search").style.animationName =
        "search-bar-anim-close";
      setSearchBarFlag(false);
    } else {
      document.getElementById("header__search").style.display = "flex";
      document.querySelector("#header__search input").focus();
      document.getElementById("header__search").style.animationName =
        "search-bar-anim-open";
      setSearchBarFlag(true);
    }
  };

  const handleSearch = (q) => {
    if (searchQueryText.trim() !== "") {
      history.push(`/search?query=${searchQueryText}`);
    }
  };

  const generateMenuItem = (title, links) => {
    return <HeaderMenuItem title={title} links={links} />;
  };

  const menuItems = (
    <div>
      {generateMenuItem("english", [
        {
          name: "Literature",
          url: "/search?category_slug=literature",
        },
        {
          name: "Novels",
          url: "/search?category_slug=novels",
        },
        {
          name: "History",
          url: "/search?category_slug=history",
        },
        {
          name: "Test Preparation",
          url: "/search?category_slug=test+prep",
        },
        {
          name: "General",
          url: "/search?category_slug=general",
        },
      ])}
      {generateMenuItem("urdu", [
        {
          name: "Literature",
          url: "/search?category_slug=urdu",
        },
        {
          name: "Afsanay",
          url: "/search?category_slug=afsaana",
        },
        {
          name: "Islamic",
          url: "/search?category_slug=islam",
        },
        {
          name: "Poetry",
          url: "/search?category_slug=poetry",
        },
        {
          name: "Story Books",
          url: "/search?category_slug=story",
        },
        {
          name: "Current Affairs",
          url: "/search?category_slug=current-affair",
        },
        {
          name: "Biographies",
          url: "/search?category_slug=biography",
        },
      ])}
      {generateMenuItem("medical books", "/search?category_slug=medical")}
      {generateMenuItem("rare books", "/search?category_slug=rare-books")}
      {generateMenuItem("ebooks", "/search?category_slug=ebooks")}
      {generateMenuItem("deals", "/deals")}
      {generateMenuItem("services", [
        { name: "Library Supply", url: "/library-supply" },
        { name: "Book Printing", url: "/book-printing" },
        { name: "Wholesale", url: "/wholesale" },
      ])}
      {generateMenuItem("about", "/about")}
    </div>
  );

  return (
    <div className="header">
      {/* top most container with logo and other stuff */}
      {(cartBoxOnPageOpen || cartBoxOnPageOpen2) && (
        <Container className="cartBoxOnPageContainer">
          <div>
            <CartBoxOnPage
              cart={props.cart}
              onMouseOver={() => {
                setCartBoxOnPageOpen2(true);
              }}
              onMouseLeave={() => {
                setTimeout(() => {
                  setCartBoxOnPageOpen2(false);
                }, 500);
              }}
            />
          </div>
        </Container>
      )}
      <div className="header__containerOne">
        <Link to="/">
          <img className="header__logo" src={logo} alt="logo" />
        </Link>
        <div className="header__iconContainer">
          <Button size="large" onClick={toggleSearchBar}>
            <div>
              <SearchIcon />
              <span>search</span>
            </div>
          </Button>
          <Link
            to="/cart"
            onMouseOver={() => {
              setCartBoxOnPageOpen(true);
            }}
            onMouseLeave={() => {
              setTimeout(() => {
                setCartBoxOnPageOpen(false);
              }, 500);
            }}
          >
            <div>
              <Badge badgeContent={props.cart_items_count} color="secondary">
                <ShoppingCartIcon />
              </Badge>
              <span>cart</span>
            </div>
          </Link>
        </div>
        <div className="header__xsIconContainer">
          <div className="header__xsSideMenuIcon">
            <Button>
              <SearchIcon onClick={toggleSearchBar} />
            </Button>
          </div>
          <div className="header__xsSideMenuIcon">
            <Link to="/cart">
              <Badge badgeContent={props.cart_items_count} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </Link>
          </div>
          <div className="header__xsSideMenuIcon">
            <IconButton onClick={toggleDrawer}>
              <MenuRoundedIcon />
            </IconButton>
          </div>
        </div>
      </div>

      {/* Side Drawer */}
      <div className="header__drawer">
        <Drawer open={drawerState}>
          <Container className="header__drawerInner">
            <span
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftRoundedIcon fontSize="large" />
              </IconButton>
            </span>
            <Divider />
            {menuItems}
          </Container>
        </Drawer>
      </div>

      {/* Search bar here that wil appear on pressing search button */}
      <div
        id="header__search"
        className="header__containerSearch"
        style={{ display: "none" }}
      >
        <IconButton onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
        <Autocomplete
          style={{ width: "100%" }}
          id="combo-box-demo"
          options={allBookNamesArr}
          getOptionLabel={(option) => option.title}
          onChange={(e) => setSearchQueryText(e.target.textContent)}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus={true}
              fullWidth={true}
              value={searchQueryText}
              onChange={(e) => setSearchQueryText(e.target.value)}
              placeholder="Search keyword/title/author..."
            />
          )}
        />
      </div>

      {/* book types list container */}
      <div className="header__containerTwo">{menuItems}</div>
    </div>
  );
}

export function AlertLine({ data, handler }) {
  if (data) {
    return (
      <div
        className="alertLine"
        style={{
          backgroundColor:
            data.type === CONSTANTS.alerts.ERROR
              ? "#ed1c24"
              : data.type === CONSTANTS.alerts.SUCCESS
              ? "#27ae60"
              : data.type === CONSTANTS.alerts.ALERT
              ? "#2980b9"
              : "#000000", //promotion
        }}
      >
        <span style={{ visibility: "hidden" }}>X</span>
        {data.line}
        <IconButton
          size="small"
          onClick={() => {
            handler(false);
          }}
        >
          <CloseRoundedIcon
            fontSize="small"
            color="inherit"
            style={{ color: "white" }}
          />
        </IconButton>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default Header;
