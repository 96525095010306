import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
// import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

function ProductCard(props) {
  return (
    <div className="ProductCard">
      {props.title && (
        <div className="ProductCard__container">
          <div className="ProductCard__img">
            <Link to={`/product?id=${props.id}`} className="link">
              <img src={props.imgURL} alt="" />
            </Link>
          </div>
          <div className="ProductCard__detail">
            <Link to={`/product?id=${props.id}`} className="link">
              <p className="ProductCard__title">
                {props.title.length > 50 ? props.title.slice(0, 35) + "..." : props.title}
              </p>
            </Link>
            <p>Rs. {props.price}</p>
            {/* <Rating
      value={((Math.random() * 10) % 3) + 3}
      readOnly
      max={5}
      size="small"
    /> */}
            {props.totalQuantity === 0 && (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                disabled={true}
              >
                <span className="ProductCard__btn">SOLD OUT</span>
              </Button>
            )}
            {props.qty > 0 && (
              <Button
              onClick={()=>{props.addToCartHandle(props.id, 1)}}
                variant="outlined"
                size="small"
                color="secondary"
              >
                <ShoppingCartIcon fontSize="small" />
                <span className="ProductCard__btn">ADD TO CART</span>
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductCard;
