import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "./style.css";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import { ProductAPI } from "../../utils/serverAPI";
import { useLocation } from "react-router-dom";
import HomeBookListCarousel from "../../components/HomeBookListCarousel";

function Product(props) {
  const paramID = new URLSearchParams(useLocation().search).get("id");
  const [id] = useState(props.data ? props.data.id : paramID);
  const [title, setTitle] = useState(props.data ? props.data.title : "");
  const [imgURL, setImgURL] = useState(props.data ? props.data.imgURL : "");
  const [price, setPrice] = useState(props.data ? props.data.price : "");
  const [totalQuantity, setTotalQuantity] = useState(
    props.data ? props.data.inventory.available : 0
  );
  const [description, setDescription] = useState(
    props.description ? props.description : ""
  );
  const [related, setRelated] = useState([]);

  useEffect(() => {
    if (!props.data && id) {
      ProductAPI.getByID(id).then((res) => {
        if (res.data) {
          setTitle(res.data.name);
          setImgURL(res.data.media ? res.data.media.source : "");
          setPrice(res.data.price.raw);
          setDescription(res.data.description);
          setTotalQuantity(res.data.inventory.available);
        }
      });
    }
  }, [id, props.data, paramID, description]);

  //for fetching related products
  useEffect(() => {
    if (title) {
      ProductAPI.getBySearchQuery(title, true).then((res) => {
        if (res.data) {
          setRelated(
            res.data.data.map((p) => ({
              id: p.id,
              imgURL: p.media ? p.media.source : "",
              title: p.name,
              description: p.description,
              price: p.price.raw,
              qty: p.inventory.available,
            }))
          );
        }
      });
    }
  }, [title]);

  return (
    <div className="bookDetails">
      {title && (
        <div className="bookDetails__container">
          <img
            src={imgURL}
            alt="product_image"
            className="bookDetails__image"
          />
          <div className="bookDetails__details">
            <div className="bookDetails__title">{title}</div>
            <div className="bookDetails__price">
              {totalQuantity > 0 && "Rs. " + price}
              {totalQuantity === 0 && "SOLD OUT"}
            </div>
            {totalQuantity > 0 && (
              <div className="bookDetails__btns">
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.addToCartHandle(id, 1, true);
                  }}
                >
                  <LocalMallRoundedIcon
                    fontSize="small"
                    style={{ marginRight: 5 }}
                  />
                  <span>Buy Now</span>
                </Button>
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    props.addToCartHandle(id, 1);
                  }}
                >
                  <ShoppingCartIcon
                    fontSize="small"
                    style={{ marginRight: 5 }}
                  />
                  <span>ADD TO CART</span>
                </Button>
              </div>
            )}
            {title && (
              <div className="bookDetails__descriptionContainer">
                {description.replaceAll("<p>", "").replaceAll("</p>", " ")}
              </div>
            )}
          </div>
        </div>
      )}
      <br />
      <br />
      {related.length > 0 && (
        <div>
          <HomeBookListCarousel
            heading="Related Products"
            books={related}
            addToCartHandle={props.addToCartHandle}
          />
        </div>
      )}
    </div>
  );
}

export default Product;
