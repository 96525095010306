import { Button, Container } from "@material-ui/core";
import React from "react";
import "../style.css";

function LibrarySupply() {
  return (
    <div className="servicesPage">
      <img
        src="assets/images/banners/services_library-supply.jpg"
        alt="services_library-supply.jpg"
        className="servicesPage__banner"
      />
      <Container className="servicesPage__description">
        <b style={{ color: "#b30d2b" }}>TheBookDot.Com</b> is one of the best
        Library Suppliers in Pakistan. We provide originals and foreign authors
        used & new books in wholesale rate for library purposes (also sell at
        per KG rate). We have provided books in some prestigious Libraries of
        Pakistan including Library of University of South Asia, Library of
        University of Imperial University, Library of Continental Medical
        College, Library of Government College Lahore, Library of Punjab
        University, Library of Oriental College, Library of Superior University,
        Library of Lords college of Health and Sciences and many more.
      </Container>
      <Container className="servicesPage__getButtonContainer">
        <h2>ARE YOU LOOKING FOR A QUALITY LIBRARY SUPPLY SERVICE?</h2>
        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          color="secondary"
          href="http://bit.ly/TBC_contact"
        >
          Get your quote now!
        </Button>
      </Container>
    </div>
  );
}

export default LibrarySupply;
