import axios from "axios";
import Commerce from "@chec/commerce.js";

export const commerce = new Commerce(process.env.REACT_APP_API_PUBLIC_KEY);

export const ProductAPI = {
  getByID: (id) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/products/${id}`,
      headers: {
        "X-Authorization": process.env.REACT_APP_API_SECRET,
      },
    });
  },
  getBySearchQuery: (query = null, category_slug = null, page = 1) => {
    let u = new URL(`${process.env.REACT_APP_API_URL}/products`);
    if (query) {
      u.searchParams.append("query", query);
    }
    if (category_slug) {
      u.searchParams.append("category_slug[]", category_slug);
    }
    u.searchParams.append("page", page);
    return axios({
      method: "GET",
      url: u,
      headers: {
        "X-Authorization": process.env.REACT_APP_API_SECRET,
      },
    });
  },
};

export const OrderAPI = {
  getByID: (id) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/orders/${id}?type=reference`,
      headers: {
        "X-Authorization": process.env.REACT_APP_API_SECRET,
      },
    });
  },
};
