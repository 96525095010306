import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { DEALS_BOOK_LIST } from "../../constants";
import "./style.css";

function ComboBox(props) {
  return (
    <Autocomplete
      id="combo-box-demo"
      options={props.optionArr}
      getOptionLabel={(option) => option}
      style={{ width: 300 }}
      onChange={(e, o) => {
        if (o) {
          document.getElementById(o.split(" ").join("-")).scrollIntoView();
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search book..."
          variant="outlined"
          fullWidth={true}
        />
      )}
    />
  );
}

function Deals() {
  return (
    <div className="deals">
      <div className="deals__header">
        <h1>We've got awesome book deals for you!</h1>
      </div>
      <img
        className="deals__banner"
        src="assets/images/banners/1.jpg"
        alt="banner"
      />
      <br />
      <div className="deals__content">
        <h3>
          Select at least 5 books from the following list, and get a special
          discount for you!
        </h3>
        <p>
          Search and choose the name of book, and write comma separated names in
          the form!
        </p>
        <br />
        <br />
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Button
            variant="outlined"
            color="secondary"
            href="https://bit.ly/2LNFi4Q"
          >
            click here for Deal Form
          </Button>
        </div>
        <br />
        <br />
        <ComboBox
          optionArr={DEALS_BOOK_LIST.sort((a, b) => (a < b ? -1 : 1)).map(
            (b) => b.split("|")[0]
          )}
        />
        <br />
        <br />
        <div>
          <h3 style={{ textAlign: "center" }}>Book Titles</h3>
          <br />
          <table>
            <tr style={{ textAlign: "center", fontWeight: "bold" }}>
              <td colSpan="2">No.</td>
              <td>Title</td>
              <td className="td-padded">Silver</td>
              <td className="td-padded">Gold</td>
              <td className="td-padded">Premium</td>
            </tr>
            {DEALS_BOOK_LIST.sort((a, b) => (a < b ? -1 : 1)).map((i, ind) => (
              <tr id={i.split("|")[0].split(" ").join("-")}>
                <td style={{ textAlign: "center" }}>⭐</td>
                <td style={{ textAlign: "center", fontWeight: "bold" }}>
                  {ind + 1}{" "}
                </td>
                <td>{i.split("|")[0]}</td>
                <td className="td-padded">{i.split("|")[1]}</td>
                <td className="td-padded">{i.split("|")[2]}</td>
                <td className="td-padded">{i.split("|")[3]}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
}

export default Deals;
