import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Cart from "./pages/Cart";
import SearchResults from "./pages/SearchResults";
import MainLayout from "./layouts/MainLayout";
import Product from "./pages/Product";
import Checkout from "./pages/Checkout";
import { commerce } from "./utils/serverAPI";
import CONSTANTS from "./constants";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PrintOrderUtil from "./pages/PrintOrderUtil";
import Deals from "./pages/Deals";
import AboutUs from "./pages/AboutUs";
import LibrarySupply from "./pages/Services/LibrarySupply";
import BookPrinting from "./pages/Services/BookPrinting";
import Wholesale from "./pages/Services/Wholesale";
import ReactGA from "react-ga";
import RouteChangeTracker from "./components/RouteChangeTracker";

function App() {
  const [cart, setCart] = useState({});
  const [checkout, setCheckout] = useState(null);
  const [discountCoupon, setDiscountCoupon] = useState(null);
  const [alertLine, setAlertLine] = useState(null);
  const [GA_flag, setGA_flag] = useState(false);
  const history = useHistory();

  useEffect(() => {
    ReactGA.initialize("UA-163800190-2");
    setGA_flag(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [window.location.href]);

  const genProps = {
    cart_items_count: cart.total_items,
    cart: cart,
    alertLine: alertLine,
    setAlertLine: setAlertLine,
  };

  const addProductToCart = (pID, qty, goToCartFlag = false) => {
    commerce.cart
      .add(pID, qty)
      .then((json) => {
        setCart(json.cart);
        if (goToCartFlag) {
          history.push("/cart");
        }
      })
      .catch((e) => {
        console.log(e);
        setAlertLine({
          line: "Internal Service Error!",
          type: CONSTANTS.alerts.ERROR,
        });
      });
  };

  const validateDiscountCode = (checkoutID, code) => {
    commerce.checkout
      .checkDiscount(checkoutID, {
        code: code,
      })
      .then((response) => {
        if (response.valid) {
          setDiscountCoupon(response);
        } else {
          setDiscountCoupon(null);
          alert("Invalid Discount Coupon!");
        }
      });
  };

  useEffect(() => {
    commerce.cart
      .retrieve()
      .then((cart) => {
        setCart(cart);
      })
      .catch((e) => {
        console.log(e);
        setAlertLine({
          line: "Internal Service Error!",
          type: CONSTANTS.alerts.ERROR,
        });
      });
  }, []);

  return (
    <Router>
      <div className="App">
        {GA_flag && <RouteChangeTracker />}
        <Switch>
          <Route path="/search">
            <MainLayout {...genProps}>
              <SearchResults addToCartHandle={addProductToCart} />
            </MainLayout>
          </Route>

          <Route path="/checkout">
            <MainLayout>
              {checkout ? (
                <Checkout
                  cart={cart}
                  checkout={checkout}
                  setCheckout={setCheckout}
                  setAlertLine={setAlertLine}
                  validateDiscountCode={validateDiscountCode}
                  discountCoupon={discountCoupon}
                />
              ) : (
                <Redirect to={"/cart"} />
              )}
            </MainLayout>
          </Route>

          <Route path="/product">
            <MainLayout {...genProps}>
              <Product
                addToCartHandle={addProductToCart}
                setAlertLine={setAlertLine}
              />
            </MainLayout>
          </Route>

          <Route path="/cart">
            <MainLayout {...genProps}>
              <Cart
                cart={cart}
                setCart={setCart}
                checkout={checkout}
                setCheckout={setCheckout}
                setAlertLine={setAlertLine}
              />
            </MainLayout>
          </Route>

          <Route
            path="/tbc-admin"
            component={() => {
              window.location.href = process.env.REACT_APP_ADMIN_LOGIN_URL;
              return null;
            }}
          />

          <Route path="/privacypolicy">
            <MainLayout>
              <PrivacyPolicy />
            </MainLayout>
          </Route>

          <Route path="/library-supply">
            <MainLayout>
              <LibrarySupply />
            </MainLayout>
          </Route>

          <Route path="/book-printing">
            <MainLayout>
              <BookPrinting />
            </MainLayout>
          </Route>

          <Route path="/wholesale">
            <MainLayout>
              <Wholesale />
            </MainLayout>
          </Route>

          <Route exact path="/">
            <MainLayout {...genProps}>
              <Home addToCartHandle={addProductToCart} />
            </MainLayout>
          </Route>

          <Route path="/print-order">
            <MainLayout {...genProps}>
              <PrintOrderUtil setAlertLine={setAlertLine} />
            </MainLayout>
          </Route>

          <Route path="/deals">
            <MainLayout {...genProps}>
              <Deals setAlertLine={setAlertLine} />
            </MainLayout>
          </Route>

          <Route path="/about">
            <MainLayout {...genProps}>
              <AboutUs />
            </MainLayout>
          </Route>
        </Switch>

        {/* floating whatsapp button */}
        <a
          href="https://api.whatsapp.com/send?phone=923134192268&text=Hi%20TheBookDotCom%20Admin%21"
          className="floatingBtn"
          target="_blank"
          rel="noreferrer"
        >
          <WhatsAppIcon fontSize="large" />
        </a>
      </div>
    </Router>
  );
}

export default App;
