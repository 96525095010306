import React from "react";
import "./style.css";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";

export default function HeaderMenuItem(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (typeof props.links === "object") {
    return (
      <div className="headerMenuItem">
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <span>{props.title}</span>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {props.links.map((link, ind) => {
            return (
              <Link
                to={link.url}
                style={{ color: "inherit", textDecoration: "none" }}
                key={ind}
              >
                <MenuItem>{link.name}</MenuItem>
              </Link>
            );
          })}
        </Menu>
      </div>
    );
  } else {
    return (
      <div className="headerMenuItem">
        <Link to={props.links} style={{ textDecoration: "none" }}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <span>{props.title}</span>
          </Button>
        </Link>
      </div>
    );
  }
}
