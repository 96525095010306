import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Grid from "@material-ui/core/Grid";
import "./style.css";
import { Container } from "@material-ui/core";
import FooterSmall from "../FooterSmall";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="footer__containerOne">
        <Container>
          <Grid container spacing={3} className="footer__containerOne">
            <Grid item xs={12} sm={4} md={3}>
              <div className="footer__featuresContainer">
                <span className="footer__containerHeading">Links</span>
                <Link to="/">HOME</Link>
                <Link to="/about">ABOUT US</Link>
                <Link to="/search?query=rare">RARE BOOKS</Link>
                <Link to="/">E-BOOKS</Link>
                <Link to="/deals">SPECIAL DEALS</Link>
                <Link to="/privacypolicy">PRIVACY POLICY</Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <div className="footer__mapContainer">
                <span className="footer__containerHeading">Get Directions</span>
                <iframe
                  title="map address"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13597.306650425035!2d74.3117393!3d31.5700888!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x79d527eb080105f1!2sThebookdotcom!5e0!3m2!1sen!2s!4v1620422725044!5m2!1sen!2s"
                  style={{
                    border: 0,
                    width: "100%",
                    height: "300px",
                    borderRadius: "10px",
                  }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </Grid>
            <Grid item sm={12} md={3}>
              <div className="footer__contactContainer">
                <span className="footer__containerHeading">Contact Us</span>
                <div className="footer__contactSocialMedia">
                  <a href="mailto:support@thebookdot.com">
                    <EmailIcon fontSize="large" />
                  </a>
                  <a href="https://www.facebook.com/thebookdotcom">
                    <FacebookIcon fontSize="large" />
                  </a>
                  <a href="https://twitter.com/thebookdot">
                    <TwitterIcon fontSize="large" />
                  </a>
                  <a href="https://www.instagram.com/onlinebooksdotcom/">
                    <InstagramIcon fontSize="large" />
                  </a>
                  <a href="https://www.linkedin.com/company/the-book-dot-com">
                    <LinkedInIcon fontSize="large" />
                  </a>
                  <a href="https://www.youtube.com/channel/UCg7ZPDV1wgRv4vK3s-u7zHQ">
                    <YouTubeIcon fontSize="large" />
                  </a>
                </div>
                <div>
                  <strong>Phone: </strong>+923134192268
                </div>
                <div>
                  <strong>Email: </strong>ceo@thebookdot.com
                </div>
                <div>
                  <strong>Address: </strong><br />
                  - <i>Pakistan</i>:
                  <br />
                  Naqi Road Anarkali Lahore
                  <br />+92-313-4192268
                  <br />04237236121
                  <br />
                  - <i>UK</i>:
                  <br />
                  277 Cranbrook Road, London
                  <br />+44-7405-425325
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <FooterSmall />
    </div>
  );
}

export default Footer;
