import React from "react";
import "./style.css";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

function AboutUs() {
  return (
    <div className="aboutus">
      <Container>
        <h1>About Us</h1>
        <br />
        <p>
          Since 1998, TheBookDot.Com has been serving as one of the most popular
          and competitive book supplier brand in the market. Now, we present to
          you, the people who worked behind the scenes to make TheBookDot.Com, a
          global brand. The Book Dot Com is Pakistan's no.1 book printing service.
        </p>
        <br />
        <br />
        <br />
        <h3>Want to join us?</h3>
        <br />
        <Button
          target="_blank"
          variant="contained"
          color="secondary"
          href="https://bit.ly/tbc_ambas"
        >
          Join as an Ambassador
        </Button>
        <br />
        <br />
        <br />
        <br />
        <Grid container spacing={3} className="">
          <Grid item xs={12} sm={4}>
            <Card>
              <CardMedia
                image="assets/images/founder.jpg"
                style={{ height: "300px", backgroundPositionY: "-64px" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  M Yousaf Chishti
                </Typography>
                <Typography gutterBottom variant="p" component="h5">
                  Founder
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{ textAlign: "justify" }}
                >
                  The Book Dot Com was actually started in 1988 by this guru of
                  Book Sellers. He is my father and started selling books since
                  1988 with 2 books at the gate of GCU, Lahore. Continuous
                  struggle and his hard work born fruits and today TBC is a
                  brand. He is well known in field of Book selling and have 35
                  years of experience.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
            <CardMedia
                image="assets/images/hammad.jpeg"
                style={{ height: "300px", backgroundPositionY: "-54px" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Syed Hammad
                </Typography>
                <Typography gutterBottom variant="p" component="h5">
                  CEO
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{ textAlign: "justify" }}
                >
                  A motivated and dedicated individual thrives on challenging
                  goals, possessing strong organizational, team building,
                  leadership, analytical and communication skills set. Running
                  this family business since 2017 and have great Entrepreneur
                  skills. Strong education professional with a bachelor focused
                  in Computer Science from Punjab University College of
                  Information and Technology, Lahore.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
            <CardMedia
                image="assets/images/haider-ali.jpg"
                style={{ height: "300px", backgroundPositionY: "0px" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Haider Ali
                </Typography>
                <Typography gutterBottom variant="p" component="h5">
                  Managing Director
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{ textAlign: "justify" }}
                >
                  An International Business Development Consultant having 10 years of experience serving in different countries i.e. UK, Middle East, Singapore, USA, Canada, Pakistan. Providing best Academic Resources Services and Solutions with 100% success rate.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
        <br />
        <br />
        <Card>
          <CardContent>
            <h2>Visit Our Offices:</h2>
            <br />
            <Grid container spacing={5}>
              <Grid xs={12} sm={6} item>
                <div>
                  <h4>Asia & US</h4>
                  <p>
                    Syed Hammad<br />
                    CEO,<br />
                    The Book Dot Com<br />
                    Naqi Road, Anarkali Lahore,<br />
                    Pakistan<br />
                    Office: (+92) 3134192268<br />
                  </p>
                </div>
              </Grid>
              <Grid xs={12} sm={6}>
                <div>
                  <h4>UK & Middle East</h4>
                  <p>
                    Ayesha Haider<br />
                    Director, Academic Resources Services and Solutions<br />
                    1 Felicia Way, Grays, Essex, RM16 4JE, UK<br />
                    UK, Europe and Middle East<br />
                    Office: (+44) 07405425325<br />
                  </p>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default AboutUs;
