import React, { useState, useEffect } from "react";
import "./style.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import { useHistory } from "react-router-dom";
import HomeBookListCarousel from "../../components/HomeBookListCarousel";
import { Button, ButtonBase, Divider } from "@material-ui/core";
import author from "../../assets/img/author.jpg";
import Container from "@material-ui/core/Container";
import PopupModal from "../../components/PopupModal";
import { ProductAPI } from "../../utils/serverAPI";
import Loader from "../../components/Loader";

function Home(props) {
  const history = useHistory();
  const [productList, setProductList] = useState([]);
  const [authorOfTheMonthBooks, setAuthorOfTheMonthBooks] = useState([]);
  const [authorOfTheMonthBooksLoading, setAuthorOfTheMonthBooksLoading] =
    useState(true);
  const [productListLoading, setProductListLoading] = useState(true);

  const shuffleProductList = (arr) => {
    let array = [...arr];
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const authorOfTheMonth = {
    name: "Paulo Coehlo",
    description:
      "Paulo Coelho de Souza is a Brazilian lyricist and novelist, best known for his novel The Alchemist. In 2014, he uploaded his personal papers online to create a virtual Paulo Coelho Foundation",
    link: "https://en.wikipedia.org/wiki/Paulo_Coelho",
    image: author,
  };
  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    let popupDate = localStorage.getItem("TBC_popup") ;
    if (popupDate) {
      const date = new Date(popupDate);
      if (date.getTime() - new Date().getTime() > 24 * 60 * 60 * 2) {
        setPopupVisible(true);
        localStorage.setItem("TBC_popup", new Date().toISOString());
      } else {
        setPopupVisible(false);
      }
    } else {
      setPopupVisible(true);
      localStorage.setItem("TBC_popup", new Date().toISOString());
    }
  }, []);

  useEffect(() => {
    ProductAPI.getBySearchQuery()
      .then((res) => {
        if (res.data && res.data.data) {
          setProductList(
            res.data.data.map((p) => ({
              id: p.id,
              imgURL: p.media ? p.media.source : "",
              title: p.name,
              price: p.price.raw,
              qty: p.inventory.available,
            }))
          );
          setProductListLoading(false);
        } else {
          setProductList([]);
          setProductListLoading(false);
        }
      })
      .catch(() => {
        setProductList([]);
        setProductListLoading(false);
      });
  }, []);

  useEffect(() => {
    // author of the month books
    ProductAPI.getBySearchQuery("paulo")
      .then((res) => {
        if (res.data && res.data.data) {
          setAuthorOfTheMonthBooks(
            res.data.data.map((p) => ({
              id: p.id,
              imgURL: p.media ? p.media.source : "",
              title: p.name,
              price: p.price.raw,
              qty: p.inventory.available,
            }))
          );
          setAuthorOfTheMonthBooksLoading(false);
        } else {
          setAuthorOfTheMonthBooks([]);
          setAuthorOfTheMonthBooksLoading(false);
        }
      })
      .catch(() => {
        setAuthorOfTheMonthBooks([]);
        setAuthorOfTheMonthBooksLoading(false);
      });
  }, []);

  return (
    <div className="home">
      {popupVisible && (
        <PopupModal
          setPopupVisible={() => {
            setPopupVisible(!popupVisible);
          }}
        />
      )}
      <OwlCarousel
        loop={true}
        items={1}
        autoplay={true}
        autoplayTimeout={3000}
        autoplayHoverPause={false}
        className="home__carousel"
        style={{ overflow: "hidden" }}
      >
        <img
          className="home__carouselItem"
          src="assets/images/banners/1.png"
          alt="banner"
        />
        <img
          className="home__carouselItem"
          src="assets/images/banners/2.png"
          alt="banner"
        />
        <img
          className="home__carouselItem"
          src="assets/images/banners/3.png"
          alt="banner"
        />
      </OwlCarousel>

      {productListLoading && (
        <div>
          <Loader />
          <br />
        </div>
      )}
      {!productListLoading && (
        <HomeBookListCarousel
          heading="featured"
          books={shuffleProductList(productList)}
          addToCartHandle={props.addToCartHandle}
        />
      )}
      {!productListLoading && (
        <HomeBookListCarousel
          heading="trending this week"
          books={shuffleProductList(productList)}
          addToCartHandle={props.addToCartHandle}
        />
      )}
      <div className="home__getRecommendationContainer">
        <div className="home__heading">Can't decide what to shop?</div>
        <p>We are introducing personalized book recommendations for you!</p>
        <ButtonBase
          className="home__partedBoxButton"
          style={{
            borderRadius: "40px",
            color: "white",
            fontFamily: "Josefin Sans",
            backgroundColor: "#0984e3",
            padding: "10px 15px",
            marginTop: "15px",
          }}
          variant="contained"
          size="large"
        >
          GET RECOMMENDATION
        </ButtonBase>
      </div>
      {productListLoading && (
        <div>
          <Loader />
          <br />
        </div>
      )}
      {!productListLoading && (
        <HomeBookListCarousel
          heading="english books"
          books={shuffleProductList(productList)}
          addToCartHandle={props.addToCartHandle}
        />
      )}
      {!productListLoading && (
        <HomeBookListCarousel
          // heading="urdu books"
          books={shuffleProductList(productList)}
          addToCartHandle={props.addToCartHandle}
        />
      )}
      <div className="home__authorOfTheMonthContainer">
        <div className="home__heading">Author of The Months</div>
        <div>
          <div className="home__authorInfoContainer">
            <img
              src={authorOfTheMonth.image}
              className="home__authorOfTheMonthImg"
              alt="author of the month"
            />
            <Divider
              orientation="vertical"
              style={{
                backgroundColor: "white",
                opacity: "0.6",
                margin: "0px 10px",
              }}
            />
            <div>
              <div className="home__authorOfTheMonthName">
                {authorOfTheMonth.name}
              </div>
              <div className="home__authorOfTheMonthDescription">
                {authorOfTheMonth.description.length > 200
                  ? authorOfTheMonth.description.slice(0, 200) + "..."
                  : authorOfTheMonth.description}
              </div>
              <Button
                size="small"
                variant="outlined"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  borderColor: "white",
                }}
                href={authorOfTheMonth.link}
              >
                Read More
              </Button>
            </div>
          </div>
          <div className="home__authorBooksContainer">
            {authorOfTheMonthBooksLoading && (
              <div>
                <Loader />
                <br />
              </div>
            )}
            {!authorOfTheMonthBooksLoading && (
              <HomeBookListCarousel
                books={authorOfTheMonthBooks}
                addToCartHandle={props.addToCartHandle}
              />
            )}
            <Button
              size="small"
              variant="outlined"
              style={{
                backgroundColor: "white",
                fontWeight: "bold",
                borderColor: "white",
              }}
              onClick={() => {
                history.push(`/search?query=${"paulo"}`);
              }}
            >
              browse all
            </Button>
          </div>
        </div>
      </div>
      {productListLoading && (
        <div>
          <Loader />
          <br />
        </div>
      )}
      {!productListLoading && (
        <HomeBookListCarousel
          heading="new arrivals"
          books={shuffleProductList(productList)}
          addToCartHandle={props.addToCartHandle}
        />
      )}
      <div className="home__giveReviewContainer">
        <div className="home__heading">Serving Since 1998</div>
        <p>Best Book printing service in Pakistan</p>
      </div>
      {productListLoading && (
        <div>
          <Loader />
          <br />
        </div>
      )}
      {!productListLoading && (
        <HomeBookListCarousel
          heading="Top Picks"
          books={shuffleProductList(productList)}
          addToCartHandle={props.addToCartHandle}
        />
      )}
      <RequestBookContainer />
      <Container className="home__publisherCarousel">
        <div className="homeBookListCarousel__label">Publishers</div>
        <OwlCarousel
          loop={true}
          autoplay={true}
          autoplayTimeout={3000}
          autoplayHoverPause={true}
          autoWidth={true}
          margin={7}
          style={{ marginTop: "10px" }}
        >
          <HomePublisherTile img="assets/images/publishers/1.jpg" />
          <HomePublisherTile img="assets/images/publishers/2.jpg" />
          <HomePublisherTile img="assets/images/publishers/3.jpg" />
          <HomePublisherTile img="assets/images/publishers/4.jpg" />
          <HomePublisherTile img="assets/images/publishers/5.jpg" />
          <HomePublisherTile img="assets/images/publishers/6.jpg" />
          <HomePublisherTile img="assets/images/publishers/7.jpg" />
          <HomePublisherTile img="assets/images/publishers/8.jpg" />
          <HomePublisherTile img="assets/images/publishers/9.jpg" />
          <HomePublisherTile img="assets/images/publishers/10.jpg" />
        </OwlCarousel>
      </Container>
      <StillNeedHelpContainer />
    </div>
  );
}

function HomePublisherTile(props) {
  return (
    <div className="HomePublisherItem">
      <img src={props.img} alt="publisher" />
    </div>
  );
}

export function StillNeedHelpContainer() {
  return (
    <Container>
      <div className="home__needHelpContactUs">
        <span>Still Need Help?</span>
        <ButtonBase
          className="home__partedBoxButton"
          style={{
            borderRadius: "40px",
            color: "white",
            fontFamily: "Josefin Sans",
            backgroundColor: "#000000",
            padding: "10px 50px",
            marginTop: "15px",
          }}
          variant="contained"
          size="large"
          href="http://bit.ly/TBC_contact"
        >
          CONTACT US
        </ButtonBase>
      </div>
    </Container>
  );
}

export function RequestBookContainer() {
  return (
    <div className="home__requestBookContainer">
      <div className="home__heading">Can't find your favourite book?</div>
      <p>Request it now and we'll try to provide it as soon as possible</p>
      <ButtonBase
        className="home__partedBoxButton"
        style={{
          borderRadius: "40px",
          color: "white",
          fontFamily: "Josefin Sans",
          backgroundColor: "#ED4C67",
          padding: "10px 15px",
          marginTop: "15px",
        }}
        href="http://bit.ly/tbc_req"
        variant="contained"
        size="large"
      >
        REQUEST A BOOK
      </ButtonBase>
    </div>
  );
}

export default Home;
