import React from "react";
import "./style.css";

function CheckoutProduct(props) {
  return (
    <div className="checkoutProduct">
      <div className="checkoutProduct__container">
        <div className="checkoutProduct__product">
          <div className="checkoutProduct__detail">
            <p className="checkoutProduct__title">
              {props.item.name.length > 50
                ? props.item.name.slice(0, 35) + "..."
                : props.item.name}
            </p>
            <p className="checkoutProduct__price">
              Rs. {props.item.price.raw.toLocaleString()}
            </p>
          </div>
        </div>
        <div className="checkoutProduct__qty">x {props.item.quantity}</div>
      </div>
    </div>
  );
}

export default CheckoutProduct;
