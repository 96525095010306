import React, { useState } from "react";
import "./style.css";
import CheckoutProduct from "../../components/CheckoutProduct";
import { Link } from "react-router-dom";
import {
  Button,
  CircularProgress,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { commerce, ProductAPI } from "../../utils/serverAPI";
import { useHistory } from "react-router-dom";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import CONSTANTS from "../../constants";
import UserDataForm from "../UserDataForm";
import Loader from "../../components/Loader";

function Checkout(props) {
  const [deliveryType, setDeliveryType] = React.useState("pakistan");
  const [delivery, setDelivery] = React.useState(
    props.checkout.shipping_methods[0].price.raw
  );
  const [allCountries, setAllCountries] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [discountCouponInput, setDiscountCouponInput] = React.useState("");
  const [userDataFormOpen, setUserDataFormOpen] = React.useState(false);
  const history = useHistory();
  const [productList, setProductList] = useState([]);
  const [productListLoading, setProductListLoading] = useState(true);

  React.useEffect(() => {
    setProductListLoading(true);
    setProductList([]);
    ProductAPI.getBySearchQuery()
      .then((res) => {
        console.log(res.data);
        if (res.data && res.data.data) {
          setProductList(
            res.data.data.map((p) => ({
              id: p.id,
              imgURL: p.media ? p.media.source : "",
              title: p.name,
              price: p.price.raw,
              qty: p.inventory.available,
            }))
          );
        } else {
          setProductList([]);
        }
        setProductListLoading(false);
      })
      .catch(() => {
        setProductList([]);
        setProductListLoading(false);
      });
  }, []);

  React.useEffect(() => {
    commerce.services.localeListCountries().then((response) => {
      if (deliveryType === "pakistan") {
        setAllCountries([{ code: "PK", name: "Pakistan" }]);
      } else {
        setAllCountries(
          props.checkout.shipping_methods[1].countries.map((c) => ({
            code: c,
            name: response.countries[c],
          }))
        );
      }
    });
  }, [deliveryType, props.checkout.shipping_methods]);

  const handleOrderConfirmation = (
    name,
    email,
    country,
    zipCode,
    phone,
    streetAddress,
    city,
    additionalOpt
  ) => {
    if (name && email && phone && streetAddress && city && country && zipCode) {
      commerce.checkout
        .getShippingOptions(props.checkout.id, {
          country: "PK",
        })
        .then((response) => {
          let dataObj = {
            line_items: {},
            customer: { email: email },
            shipping: {
              name: name,
              street: streetAddress,
              town_city: city,
              country: country,
              postal_zip_code: zipCode,
            },
            fulfillment: {
              shipping_method: response[0].id,
            },
            extra_fields: {
              extr_RyWOwmdjKlnEa2: phone,
              extr_ypbroE6Y658n4e: additionalOpt,
            },
            payment: {
              gateway: "manual",
              manual: {
                id: props.checkout.gateways.manual[0].id,
              },
            },
          };
          props.cart.line_items.forEach((i) => {
            let temp = {
              [i.id]: {
                quantity: i.quantity,
              },
            };
            dataObj.line_items[i.id] = temp[i.id];
          });

          commerce.checkout
            .capture(props.checkout.id, dataObj)
            .then((r) => {
              history.push("/");
              props.setAlertLine({
                line: `ORDER SUCCESSFULL\nPlease check yout email ${r.customer.email} for order reciept!\nThank You!`,
                type: CONSTANTS.alerts.SUCCESS,
              });
            })
            .catch((e) => {
              console.log(e);
              props.setAlertLine({
                line: "Internal Service Error!",
                type: CONSTANTS.alerts.ERROR,
              });
            });
        })
        .catch((e) => {
          console.log(e);
          props.setAlertLine({
            line: "Internal Service Error!",
            type: CONSTANTS.alerts.ERROR,
          });
        });
    } else {
      props.setAlertLine({
        line: "Please provide All the necessary Data",
        type: CONSTANTS.alerts.ALERT,
      });
    }
  };

  return (
    <div className="checkout">
      <div className="checkout__heading">
        <span>Checkout</span>
      </div>
      {productListLoading && <Loader />}
      {!productListLoading &&
        props.cart &&
        props.checkout &&
        props.cart.line_items &&
        props.cart.line_items.length > 0 && (
          <div className="checkout__productsContainer">
            <div className="checkout__productList">
              {props.cart.line_items.map((itemCart, index) => (
                <CheckoutProduct
                  removeFromCartHandle={props.removeFromCartHandle}
                  key={index}
                  item={{
                    ...itemCart,
                    index,
                    ...productList[
                      productList.filter((p) => p.id === itemCart.id)
                    ],
                  }}
                />
              ))}
            </div>
            <div className="checkout_totalInfo">
              <h3 style={{ fontFamily: "Open Sans" }}>TOTAL</h3>
              <h2 style={{ fontFamily: "Oswald", color: "#d8024d" }}>
                Rs.{" "}
                {(
                  props.cart.subtotal.raw +
                  delivery -
                  (props.discountCoupon
                    ? props.discountCoupon.amount_saved.raw
                    : 0)
                ).toLocaleString()}
              </h2>
              <hr />
              <p>
                <small style={{ fontFamily: "Open Sans" }}>
                  Subtotal:{" "}
                  <strong>
                    {props.cart.subtotal.raw.toLocaleString()}
                    {props.discountCoupon
                      ? " - " + props.discountCoupon.amount_saved.raw
                      : ""}
                  </strong>
                </small>
              </p>
              <p>
                <small style={{ fontFamily: "Open Sans" }}>
                  Delivery Charges: <strong>Rs. {delivery}</strong>
                </small>
              </p>
              <hr />
              {!props.discountCoupon && (
                <div>
                  <TextField
                    variant="outlined"
                    placeholder="Discount Coupon (if any)"
                    fullWidth
                    value={discountCouponInput}
                    onChange={(e) => {
                      setDiscountCouponInput(e.target.value);
                    }}
                  />
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    style={{ width: "160px", marginTop: "8px" }}
                    onClick={() => {
                      props.validateDiscountCode(
                        props.checkout.id,
                        discountCouponInput
                      );
                    }}
                  >
                    apply coupon
                  </Button>
                </div>
              )}
              {props.discountCoupon && (
                <p>
                  Discount Coupon:
                  <br />
                  <strong>{props.discountCoupon.code}</strong> is applied
                </p>
              )}
              <hr />
              <br />
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Delivery Type</FormLabel>
                <RadioGroup
                  value={deliveryType}
                  onChange={(e) => {
                    setDeliveryType(e.target.value);
                    if (e.target.value === "pakistan") {
                      setDelivery(props.checkout.shipping_methods[0].price.raw);
                    } else {
                      setDelivery(props.checkout.shipping_methods[1].price.raw);
                    }
                  }}
                >
                  <FormControlLabel
                    value="pakistan"
                    control={<Radio />}
                    label="Pakistan"
                  />
                  <FormControlLabel
                    value="international"
                    control={<Radio />}
                    label="International"
                  />
                </RadioGroup>
              </FormControl>
              <hr />

              <div className="checkout_actionBtnContainer">
                <Button
                  color="secondary"
                  variant="outlined"
                  style={{
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    props.setCheckout(null);
                    history.push("/cart");
                  }}
                >
                  <ArrowBackRoundedIcon fontSize="small" color="secondary" />
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ width: "160px" }}
                  onClick={() => {
                    if (!deliveryType) {
                      props.setAlertLine({
                        line: "Please Select Delivery Type",
                        type: CONSTANTS.alerts.ALERT,
                      });
                    } else {
                      setUserDataFormOpen(true);
                    }
                  }}
                >
                  {!loading && "PROCEED"}
                  {loading && <CircularProgress size="1.8em" color="inherit" />}
                </Button>
              </div>
            </div>
          </div>
        )}
      {!productListLoading &&
        props.cart &&
        props.cart.line_items &&
        props.cart.line_items.length === 0 && (
          <div className="checkout__cartEmpty">
            <div className="checkout__cartEmptyLine1">Cart is Empty 😟</div>
            <div className="checkout__cartEmptyLine2">
              Grab something awesome <Link to="/">HERE</Link> 😃
            </div>
          </div>
        )}
      {!productListLoading &&
        props.cart &&
        props.cart.line_items &&
        userDataFormOpen && (
          <div className="checkout__userDataFormScreen">
            <UserDataForm
              setUserDataFormOpen={setUserDataFormOpen}
              loading={loading}
              setLoading={setLoading}
              handleOrderConfirmation={handleOrderConfirmation}
              countryList={allCountries}
            />
          </div>
        )}
    </div>
  );
}

export default Checkout;
