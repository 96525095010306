import React, { useEffect, useState } from "react";
import "./style.css";
import CartProduct from "../../components/CartProduct";
import { Link, useHistory } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import { ProductAPI, commerce } from "../../utils/serverAPI";
import Loader from "../../components/Loader";

function Cart(props) {
  const [checkoutBtnLoading, setCheckoutBtnLoading] = React.useState(false);
  const history = useHistory();
  const [productList, setProductList] = useState([]);
  const [productListLoading, setProductListLoading] = useState(true);

  const emptyCart = () => {
    commerce.cart
      .empty()
      .then((json) => {
        props.setCart(json.cart);
      })
      .catch((e) => {
        console.log(e);
        props.setAlertLine({
          line: "Internal Service Error!",
          type: "ERROR",
        });
      });
  };

  useEffect(() => {
    setProductListLoading(true);
    setProductList([]);
    ProductAPI.getBySearchQuery()
      .then((res) => {
        console.log(res.data);
        if (res.data && res.data.data) {
          setProductList(
            res.data.data.map((p) => ({
              id: p.id,
              imgURL: p.media ? p.media.source : "",
              title: p.name,
              price: p.price.raw,
              qty: p.inventory.available,
            }))
          );
        } else {
          setProductList([]);
        }
        setProductListLoading(false);
      })
      .catch(() => {
        setProductList([]);
        setProductListLoading(false);
      });
  }, []);

  useEffect(() => {
    if (props.checkout) {
      history.push("/checkout");
    }
  }, [props.checkout, history]);

  const removeProductFromCart = (line_item_id) => {
    commerce.cart
      .remove(line_item_id)
      .then((json) => {
        props.setCart(json.cart);
      })
      .catch((e) => {
        console.log(e);
        props.setAlertLine({
          line: "Internal Service Error!",
          type: "ERROR",
        });
      });
  };

  const generateCheckoutToken = () => {
    commerce.checkout
      .generateTokenFrom("cart", commerce.cart.id())
      .then((response) => {
        props.setCheckout(response);
      })
      .catch((e) => {
        console.log(e);
        props.setAlertLine({
          line: "Internal Service Error!",
          type: "ERROR",
        });
      });
  };

  return (
    <div className="cart">
      <div className="cart__heading">
        <span>Your Cart</span>
      </div>
      {productListLoading && props.cart && <Loader />}
      {!productListLoading &&
        props.cart &&
        props.cart.line_items &&
        props.cart.line_items.length > 0 && (
          <div className="cart__productsContainer">
            <div className="cart__productList">
              {props.cart.line_items.map((itemCart, index) => (
                <CartProduct
                  removeFromCartHandle={removeProductFromCart}
                  item={{
                    ...itemCart,
                    index,
                    ...productList[
                      productList.filter((p) => p.id === itemCart.id)
                    ],
                  }}
                />
              ))}
            </div>
            <div className="cart_subtotalInfo">
              <h3 style={{ fontFamily: "Open Sans" }}>SUBTOTAL</h3>
              <h2 style={{ fontFamily: "Oswald", color: "#d8024d" }}>
                Rs. {props.cart.subtotal.raw.toLocaleString()}
              </h2>
              <br />
              <hr />
              <br />
              <div className="cart_actionBtnContainer">
                <div>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setCheckoutBtnLoading(true);
                      generateCheckoutToken();
                    }}
                  >
                    {!checkoutBtnLoading && "Checkout"}
                    {checkoutBtnLoading && (
                      <CircularProgress size="1.6em" color="inherit" />
                    )}
                  </Button>
                </div>
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      emptyCart();
                    }}
                  >
                    Empty Cart
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      {props.cart &&
        props.cart.line_items &&
        props.cart.line_items.length === 0 && (
          <div className="cart__cartEmpty">
            <div className="cart__cartEmptyLine1">Cart is Empty 😟</div>
            <div className="cart__cartEmptyLine2">
              Grab something awesome <Link to="/">HERE</Link> 😃
            </div>
          </div>
        )}
    </div>
  );
}

export default Cart;
