import React, { useEffect, useState } from "react";
import "./style.css";
import ProductCard from "../../components/ProductCard";
import { RequestBookContainer, StillNeedHelpContainer } from "../Home";
import { ProductAPI } from "../../utils/serverAPI";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import { Button } from "@material-ui/core";

function SearchResults(props) {
  const queryParam = new URLSearchParams(window.location.search).get("query");
  const categoryParam = new URLSearchParams(window.location.search).get(
    "category_slug"
  );
  const [productList, setProductList] = useState([]);
  const [productListLoading, setProductListLoading] = useState(true);
  const location = useLocation();
  const [pagination, setPagination] = useState(null);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const handleLoadMore = () => {
    setLoadMoreLoading(true);
    ProductAPI.getBySearchQuery(
      queryParam,
      categoryParam,
      pagination.current_page + 1
    )
      .then((res) => {
        if (res.data && res.data.data) {
          setProductList([
            ...productList,
            ...res.data.data.map((p) => ({
              id: p.id,
              imgURL: p.media ? p.media.source : "",
              title: p.name,
              price: p.price.raw,
              qty: p.inventory.available,
            })),
          ]);
        }
        setLoadMoreLoading(false);
        if (res.data.meta && res.data.meta.pagination) {
          setPagination(res.data.meta.pagination);
        }
      })
      .catch(() => {
        setProductList([]);
        setPagination(null);
        setLoadMoreLoading(false);
      });
  };

  useEffect(() => {
    setProductListLoading(true);
    setProductList([]);
    ProductAPI.getBySearchQuery(queryParam, categoryParam)
      .then((res) => {
        if (res.data && res.data.data) {
          setProductList(
            res.data.data.map((p) => ({
              id: p.id,
              imgURL: p.media ? p.media.source : "",
              title: p.name,
              price: p.price.raw,
              qty: p.inventory.available,
            }))
          );
        } else {
          setProductList([]);
        }
        if (res.data.meta && res.data.meta.pagination) {
          setPagination(res.data.meta.pagination);
        }
        setProductListLoading(false);
      })
      .catch(() => {
        setProductList([]);
        setPagination(null);
        setProductListLoading(false);
      });
  }, [queryParam, categoryParam, location]);

  return (
    <div className="searchResults">
      {productListLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Loader color="inherit" />
        </div>
      )}
      {!productListLoading && productList.length > 0 && (
        <div className="searchResults__container">
          {productList.map((book) => (
            <ProductCard
              id={book.id}
              imgURL={book.imgURL}
              title={book.title}
              price={book.price}
              qty={book.qty}
              addToCartHandle={props.addToCartHandle}
            />
          ))}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingTop: "25px",
              paddingBottom: "25px",
            }}
          >
            {!loadMoreLoading && pagination.links.next && (
              <Button variant="contained" onClick={handleLoadMore}>
                Load More
              </Button>
            )}
            {loadMoreLoading && <Loader color="inherit" />}
          </div>
        </div>
      )}
      {!productListLoading && productList.length === 0 && (
        <div className="searchResults__notFound">
          Oops... unable to find books that you want!
        </div>
      )}
      <RequestBookContainer />
      <StillNeedHelpContainer />
    </div>
  );
}

export default SearchResults;
