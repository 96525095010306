import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import "./style.css";
import Select from "@material-ui/core/Select";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

function UserDataForm(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [additionalOpt, setAdditionalOpt] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <div className="userDataForm">
      <div className="userDataForm__form">
        <h2 style={{ textAlign: "center" }}>Enter Your Details</h2>
        <TextField
          autoFocus
          label="Name"
          variant="outlined"
          fullWidth
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          autoFocus
          label="Email"
          variant="outlined"
          fullWidth
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Street Address"
          variant="outlined"
          fullWidth
          name="street address"
          value={streetAddress}
          onChange={(e) => setStreetAddress(e.target.value)}
        />
        <TextField
          label="City"
          variant="outlined"
          fullWidth
          name="city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <FormControl variant="outlined">
          <InputLabel id="country-select-label">Country</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            label="Country"
          >
            {props.countryList.map((c) => (
              <MenuItem value={c.code}>{c.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Phone"
          variant="outlined"
          fullWidth
          name="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          label="Zip/Postal Code"
          variant="outlined"
          fullWidth
          name="zip code"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
        />
        <TextField
          label="Extra Instuction (Optional)"
          variant="outlined"
          fullWidth
          name="extra instruction"
          value={additionalOpt}
          onChange={(e) => setAdditionalOpt(e.target.value)}
        />
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            color="secondary"
            variant="outlined"
            style={{
              width: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              props.setUserDataFormOpen(false);
            }}
          >
            <ArrowBackRoundedIcon fontSize="small" color="secondary" />
          </Button>{" "}
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => {
              props.handleOrderConfirmation(
                name,
                email,
                country,
                zipCode,
                phone,
                streetAddress,
                city,
                additionalOpt
              );
            }}
          >
            {!props.loading && "CONFIRM ORDER"}
            {props.loading && <CircularProgress size="1.8em" color="inherit" />}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UserDataForm;
