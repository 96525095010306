import { Button } from "@material-ui/core";
import React from "react";
import "./style.css";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";

function PopupModal({ setPopupVisible }) {
  const history = useHistory();

  return (
    <div className="popupModal">
      <div className="popupModal__box">
        <h2>Are you interested for deal books? 😍</h2>
        <br />
        <p>We offer three qualities of English Novels</p>
        <br />
        <span>
          <span>⭐ Silver</span>
          <span>⭐ Gold</span>
          <span>⭐ Premium</span>
        </span>
        <br />
        <p>
          We usually sell Premium Quality books because of customer satisfaction
          but if you are looking for low cost novels just visit our deal page.
          🎉
        </p>
        <br />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            history.push("/deals");
          }}
        >
          Checkout Awesome Deals
        </Button>
        <br />
        <br />
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          onClick={setPopupVisible}
        >
          continue to the website <ArrowForwardIcon fontSize="small" />
        </Button>
      </div>
    </div>
  );
}

export default PopupModal;
