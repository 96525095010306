import React from "react";
import { Button, Container } from "@material-ui/core/";

function PrivacyPolicy() {
  return (
    <div className="privacyPolicy">
      <Container>
        <h1 style={{ margin: "20px" }}>TheBookDotCom - Privacy Policy</h1>
        <li>
          TheBookDotCom store the ip address of each logged in session (and not
          the logged out visitor session) for the sake of traffic and sales data
          analysis which is not disclosed to any third party vendors.
        </li>
        <br />
        <li>
          TheBookDotCom uses the user information (email, name, address,
          country, phone) responsibly and this data is only used for order
          delivery, user contact etc. We do not sell or distribute this data to
          third party vendors as well.
        </li>
        <br />
        <li>
          TheBookDot.Com Website is developed and powered by{" "}
          <a href="https://SignifyPro.com">Signify Pro</a> who has implemented
          strong security and privacy measures to make the data management
          procedures secure and private. For More information, contact Codefumes
          official website.
        </li>
        <br />
        <div style={{ display: "felx", justifyContent: "center" }}>
          <Button variant="contained" href="/" color="secondary">
            GO TO HOME
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
