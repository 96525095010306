import React from "react";
import "./style.css";
import CodefumesIcon from "../CodefumesIcon";

function FooterSmall() {
  return (
    // copyright attribution
    <div
      className="footerSmall"
      style={{
        textAlign: "center",
        marginBottom: "10px",
        fontSize: "0.8em",
        fontFamily: "Roboto",
      }}
    >
      Copyright &copy; 1998-{new Date().getFullYear()}, TheBookDot.com
      <br />
      <div className="footerSmall__codefumesAttribution">
        Powered By{" "}
        <strong>
          <a href="https://SignifyPro.com">
            <CodefumesIcon fontSize="small" style={{ display: "inline" }} />
            Signify Pro 
          </a>
        </strong>
      </div>
    </div>
  );
}

export default FooterSmall;
