import { Button, CircularProgress, Container } from "@material-ui/core";
import React, { useState } from "react";
import "./style.css";
import { OrderAPI } from "../../utils/serverAPI";

function PrintOrderUtil(props) {
  const [refID, setRefID] = useState("");
  const [loadingFlag, setLoadingFlag] = useState(false);

  const handlePrintOrder = () => {
    if (refID === "") {
      props.setAlertLine({
        line: "Please enter reference id",
        type: "ERROR",
      });
      return;
    } else {
      setLoadingFlag(true);
      OrderAPI.getByID(refID)
        .then((res) => {
          setLoadingFlag(false);
          props.setAlertLine({
            line: "Done!",
            type: "SUCCESS",
          });
          setRefID("");
          let orderData = {
            refID: res.data.customer_reference,
            name: res.data.shipping.name,
            address: `${res.data.shipping.street}, ${res.data.shipping.town_city}, Pakistan, ${res.data.shipping.postal_zip_code}`,
            phone: res.data.extra_fields[0].value,
            total: res.data.order_value.raw,
          };

          //now make pdf
          document.write(`
          <div style="font-size: 1.4em;">
        <h1 style="text-align: center;">TheBookDotCom - Online Store</h1>
        <h3 style="text-align: center;">Order Invoice</h3>
        <br/>
        <table>
            <tr>
                <td>Order Ref.#</td>
                <td style="font-weight:bold;">${orderData.refID}</td>
            </tr>
            <tr>
                <td>Name</td>
                <td style="font-weight:bold;">${orderData.name}</td>
            </tr>
            <tr>
                <td>Address</td>
                <td style="font-weight:bold;">${orderData.address}</td>
            </tr>
            <tr>
                <td>Phone</td>
                <td style="font-weight:bold;">${orderData.phone}</td>
            </tr>
            <tr>
                <td>Total</td>
                <td style="font-weight:bold;">${orderData.total} PKR</td>
            </tr>
        </table>
        <br/>
        <br/>
        <p>Note: In case of any issue regarding order, feel free to contact support@thebookdot.com</p>
        <br/>
        <br/>
        <p  style="text-align: center;">www.thebookdot.com - Powered by Codefumes</p>
        <hr/>
        <small style="text-style: italic;">For any IT services, web development, custom software development etc., contact Codefumes on info@codefumes.tech to get services from qualified engineers. Thank you</small>
    </div>
          `);
        })
        .catch(() => {
          setLoadingFlag(false);
          props.setAlertLine({
            line: "Internal Server Error",
            type: "ERROR",
          });
        });
    }
  };

  return (
    <Container className="printOrderUtil">
      <h3 style={{ textAlign: "left" }}>Enter Order Reference ID</h3>
      <input
        type="text"
        className="printOrderUtil__input"
        id="order_ref"
        onChange={(e) => setRefID(e.target.value)}
      />
      <br />
      <Button variant="contained" color="primary" onClick={handlePrintOrder}>
        {!loadingFlag && "PRINT ORDER"}
        {loadingFlag && <CircularProgress size="1.4em" />}
      </Button>
    </Container>
  );
}

export default PrintOrderUtil;
