import React from "react";
import { CircularProgress } from "@material-ui/core";
import { Container } from "@material-ui/core";

export default function Loader() {
  return (
    <Container
      style={{ display: "flex", justifyContent: "center", padding: "20px" }}
    >
      <CircularProgress />
    </Container>
  );
}
