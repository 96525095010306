import React from "react";
import "./style.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import ProductCard from "../ProductCard";
import Container from "@material-ui/core/Container";

function HomeBookListCarousel(props) {
  return (
    <Container className="homeBookListCarousel">
      <div className="homeBookListCarousel__label">{props.heading}</div>
      <OwlCarousel
        loop={true}
        autoplay={true}
        autoplayTimeout={3000}
        autoplayHoverPause={true}
        autoWidth={true}
      >
        {props.books.map((book, ind) => (
          <ProductCard
            id={book.id}
            imgURL={book.imgURL}
            title={book.title}
            price={book.price}
            qty={book.qty}
            key={ind}
            addToCartHandle={props.addToCartHandle}
          />
        ))}
      </OwlCarousel>
    </Container>
  );
}

export default HomeBookListCarousel;
