const CONSTANTS = {
  alerts: {
    ERROR: "ERROR",
    ALERT: "ALERT",
    SUCCESS: "SUCCESS",
    PROMO: "PROMO",
  },
};

export default CONSTANTS;

export const DEALS_BOOK_LIST = [
  "World Between Us By Sara Naveed|****|****|****",
  "Horrid Henry By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Nits By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Hilariously Horrid Joke Book By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - And The Zombie Vampire (2) By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - And The Zombie Vampire By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - And The Mega-Mean Time Machine By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - And The Mummy's Curse By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Haunted House By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Jolly Joke Book By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - And The Secret Club By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Purple Hand Gang By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Christmas Cracker By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Monster Movie By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Stink Bomb By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Dinosaurs By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Sports By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - And The Abominable Snowman By Francesca Simon|199.Rs|Rs.300|****",
  "Horrid Henry - Gets Rich Quick By Francesca Simon|199.Rs|Rs.300|****",
  "Diary Of A Wimpy Kid - The Getaway|199.Rs|Rs.300|Rs.500",
  "Horrid Henry - Robs The Bank By Francesca Simon|199.Rs|Rs.300|****",
  "Inferno By Dan Brown|199.Rs|Rs.300|Rs.500",
  "The Art Of Reading Minds By Henrik Fexeus|****|****|****",
  "The Winner Stands Alone By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "The Witch Of Portobello By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "Brida By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "The Devil And Miss Prym By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "The Fifth Mountain By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "On Palestine By Noam Chomsky|199.Rs|Rs.300|****",
  "Dork Diaries - Tv Star By Rachel Renee Russell|229.Rs|Rs.300|****",
  "Dork Diaries - Skating Sensation By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - Puppy Love By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - Pop Star By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - Party Time By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - OMG all about me diary By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - How To Dork Your Diary By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - Holiday Heartbreak By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - Frenemies Forever By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - Crush Catastrophe By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - Birthday Drama By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - Once Upon A Dork By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - Dear Dork By Rachel Renee Russell|229.Rs|Rs.350|****",
  "Dork Diaries - Tales From A Not-so-fabulous Life By Rachel Renee Russell|229.Rs|Rs.350|****",
  "The Secret Barrister|****|****|****",
  "The Greatest Adventures Of Sherlock Holmes By Sir Arthur Anon Doyle|199.Rs|****|****",
  "The 8th Habit From Effectiveness To Greatness By Stephen Covey|199.Rs|Rs.300|Rs.500",
  "How Democracy Die By Steven Levitsky|199.Rs|Rs.300|****",
  "Typically Tanya By Taha Kehar|****|Rs.300|****",
  "The Influential Mind By Tali Sharot|199.Rs|Rs.300|****",
  "Ayesha At Last By Uzma Jalaluddin|****|Rs.300|****",
  "Prisoners Of Geography By Tim Marshall|199.Rs|Rs.300|****",
  "Carve The Mark By Veronica Roth|****|Rs.300|****",
  "The Pleasure Of Philosophy By Will Durant|199.Rs|Rs.300|Rs.500",
  "Me And Rumi By William Chittick|199.Rs|Rs.300|Rs.500",
  "Lord Of The Flies By William Golding|199.Rs|Rs.300|Rs.500",
  "Carrie's War By Nina Bawden|199.Rs|Rs.300|****",
  "Everything Everything By Nicola Yoon|****|Rs.300|****",
  "Life Without Limits By Nick Vujicic|199.Rs|Rs.300|****",
  "Longest Ride By Nicholas Sparks|****|Rs.300|****",
  "The Best Of Me By Nicholas Sparks|****|Rs.300|****",
  "See Me By Nicholas Spark|****|Rs.300|****",
  "The Rubais Of Rumi By Nevit O Ergin|Rs.199|Rs.300|Rs.500",
  "When The Moon Shines By Day By Nayantara Sahgal|****|Rs.300|****",
  "Revive Your Heart By Nouman Ali Khan|Rs.199|Rs.300|****",
  "Success The Best Of Napoleon Hill|Rs.199|Rs.300|Rs.500",
  "Good As Gold The Power Of A Positive Mental Attitude By Napoleon Hill|Rs.199|Rs.300|****",
  "Kafka On The Shore By Haruki Murakami|Rs.199|Rs.300|****",
  "Lockdown By Peter May|****|****|****",
  "The Glass Castle By Jeannette Walls|****|Rs.300|****",
  "What Light By Jay Asher|Rs.199|Rs.300|****",
  "Thirteen Reasons Why By Jay Asher|Rs.199|Rs.300|Rs.500",
  "Upheaval How Nations Cope With Crisis And Changed By Jared Diamond|****|Rs.300|****",
  "Pride And Prejudice By Jane Austen|199.Rs|Rs.300|Rs.500",
  "I'll Give You The Sun By Jandy Nelson|Rs.199|Rs.300|****",
  "Girl Online Going Solo By Zoe Sugg|Rs.199|Rs.300|****",
  "Girl Online By Zoe Sugg|****|Rs.300|Rs.500",
  "Fantastic Beasts The Original Screenplay By JK Rowling|****|****|****",
  "We'll Always Have Summer By Jenny Han|****|Rs.300|****",
  "Filed Notes On Love Jennifer E Smith|****|Rs.300|Rs.500",
  "Maze Runner By James Dashner|Rs.199|Rs.300|Rs.500",
  "The Death Cure By James Dashner|Rs.199|Rs.300|****",
  "The Muqaddimah An Introduction To History The Classic Islamic History Of The World By Ibn Khaldun|Rs.199|Rs.300|Rs.500",
  "Lahore In The Time Of Raj By Ian Talbot & Tahir Kamran|****|Rs.300|****",
  "Am I Normal Yet By Holly Bourne|****|Rs.300|****",
  "The Evolution Of Ghalib By Hasan Abdullah|****|Rs.300|****",
  "The Wife Between Us By Greer Hendricks|Rs.199|Rs.300|****",
  "This Family Isn't Nuclear. It's Toxic From The Authore Of Gone Girl Sharp Objects By Gillian Flynn|****|Rs.300|****",
  "Home Is Where The Lies Are From The Author Of Gone Girl Dark Places By Gillian Flynn|****|Rs.300|****",
  "My Family And Other Animals By Gerald Durrell|Rs.199|Rs.300|****",
  "Lincoln In The Bardo By George Saunders|****|Rs.300|****",
  "Nightflyers And Other Stories By George R.R Martin|****|Rs.300|****",
  "I Have Lost My Way By Gayle Forman|199.Rs|Rs.300|****",
  "Why Now Is The Time To Crush It Cash In Your Passion By Gary Vay Ner Chuk|****|Rs.300|****",
  "What The Luck? By Gray Smith|****|Rs.300|****",
  "A Man Called Ove By Fredrik Backman|199.Rs|Rs.300|****",
  "rumi swallowing the sun by franklin d lewis|Rs.199|Rs.300|Rs.500",
  "Political Order And Political Decay By Francis Fukuyama|****|Rs.300|Rs.500",
  "The Origins Of Political Order By Francis Fukuyama|****|Rs.300|****",
  "The Art Of Logic: How To Make Sense In A World That Doesn't By Eugenia Cheng|****|Rs.300|****",
  "Che Guevara and the Cuban Revolution: Writings and Speeches of Ernesto Che Guevara by Ernesto Che Guevara|****|Rs.300|****",
  "The Giver Of Stars By Jojo Moyes|****|Rs.300|****",
  "Barking Up The Wrong Tree By Eric Barker|Rs.199|Rs.300|****",
  "Napoleon: A Great Soldier Politician Lover And First Modern European By Emil Ludwig|****|Rs.300|****",
  "Culture And Imperialism By Edward W Said|Rs.255|Rs.300|****",
  "Teach Your Child How To Think By Edward De Bono|****|Rs.300|****",
  "Six Thinking Hats By Edward De Bono|Rs.199|Rs.300|****",
  "Lateral Thinking By Edward De Bono|****|Rs.300|****",
  "How To Have A Beautiful Mind Book By Edward De Bono|199.Rs|Rs.300|Rs.500",
  "Oneness With All Life By Eckhart Tolle|****|Rs.300|Rs.500",
  "A New Earth By Eckhart Tolle|199.Rs|Rs.300|****",
  "The Railway Children By Edith Nesbit|199.Rs|Rs.300|****",
  "We Were Liars By E. Lockhart|199.Rs|Rs.300|Rs.500",
  "The Magic Of Getting What You Want By David J Schwartz|199.Rs|Rs.300|****",
  "The Border The Legacy Of A Century Of Anglo-irish Politics By Diarmaid Ferriter|****|Rs.300|****",
  "Bad Dad By David Walliams|****|Rs.300|****",
  "Where Power Stops By David Runciman|****|Rs.300|****",
  "How Democracy Ends By David Runciman|199.Rs|Rs.300|Rs.500",
  "The Narrow Corridor States Societies And The Fate Of Liberty By Daron Acemoglu $ James A Robinson|****|Rs.300|****",
  "Into The Minds Of Warren Buffett And Charlie Munger By Daniel Pecaut With Corey Wrenn|****|Rs.300|****",
  "The Modern Breakup By Daniel Chidiac|****|Rs.300|****",
  "Start-up Nation The Story Of Israel's Economic Miracle By Dan Senor And Saul Singer|199.Rs|Rs.300|****",
  "The New Science Of Building Brain Power By Dan Hurley|****|Rs.300|****",
  "Origin By Dan Brown|199.Rs|Rs.300|Rs.500",
  "The Lost Symbol By Dan Brown|199.Rs|Rs.300|Rs.500",
  "The Da Vinci Code By Dan Brown|199.Rs|Rs.300|Rs.500",
  "Angels And Demons By Dan Brown|199.Rs|Rs.300|Rs.500",
  "How To Develop Self-confidence And Influence People By Public Speaking By Dale Carnegie|199.Rs|Rs.300|Rs.500",
  "Born With Wings By Daisy Khan|****|Rs.300|Rs.500",
  "Verity By Colleen Hoover|****|Rs.300|****",
  "Slammed By Colleen Hoover|****|Rs.300|****",
  "Losing Hope By Colleen Hoover|199.Rs|Rs.300|****",
  "Confess By Colleen Hoover|199.Rs|Rs.300|****",
  "The Essential Rumi By Coleman Barks|****|Rs.300|Rs.500",
  "Rumi's Little Book Of Love And Laughter By Coleman Barks|199.Rs|Rs.300|Rs.500",
  "Rumi The Big Red Book By Coleman Barks|****|Rs.300|Rs.500",
  "The Girl In Room 105 By Chetan Bhagat|****|Rs.300|****",
  "One Indian Girl By Chetan Bhagat|****|Rs.300|****",
  "Oliver Twist By Charles Dickens|199.Rs|Rs.300|****",
  "A Tale Of Two Cities By Charles Dickens|199.Rs|Rs.300|Rs.500",
  "A Christmas Carol By Charles Dickens|199.Rs|Rs.300|****",
  "Little Fires Everywhere Book By Celeste Ng|****|Rs.300|****",
  "P S I Love You Movie By Cecelia Ahern|199.Rs|Rs.300|****",
  "Rising Strong How The Ability To Reset Transforms The Way We Live Love Parent And Lead By Brene Brown|****|Rs.300|****",
  "Feast With A Taste Of Amir Khusro By Bismia Trimizi|****|Rs.300|****",
  "The Witcher - Lady of the lake by andrzej sapkowski|249.Rs|Rs.350|****",
  "The Witcher - Season Of Storms By Andrzej Sapkowski|249.Rs|Rs.350|****",
  "The Witcher - The Tower Of Swallows By Andrzej Sapkowski|249.Rs|Rs.350|****",
  "The Witcher - Baptism Of Fire By Andrzej Sapkowski|249.Rs|Rs.350|****",
  "The Witcher - Time Of Contempt By Andrzej Sapkowski|249.Rs|Rs.350|****",
  "The Witcher - Blood Of Elves By Andrzej Sapkowski|249.Rs|Rs.350|****",
  "The Witcher - Sword Of Destiny By Andrzej Sapkowski|249.Rs|Rs.350|****",
  "The Witcher - The Last Wish By Andrzej Sapkowski|249.Rs|Rs.350|****",
  "Game Of Thrones - A Dance With Dragons 2: After The Feast By George R.r. Martin|249.Rs|Rs.400|****",
  "Game Of Thrones - A Dance With Dragons: Dreams And Dust By George R.r. Martin|249.Rs|Rs.400|****",
  "Game Of Thrones - A Feast For Crows By George R.r. Martin|249.Rs|Rs.400|****",
  "Game Of Thrones - A Storm Of Swords 2: Blood and Gold By George R.r. Martin|249.Rs|Rs.400|****",
  "Game Of Thrones - A Storm Of Swords: Steel And Snow By George R.r. Martin|249.Rs|Rs.400|****",
  "Game Of Thrones - A Clash Of Kings By George R.r. Martin|249.Rs|Rs.400|****",
  "A Game Of Thrones By George R.r. Martin|249.Rs|Rs.400|****",
  "The Girl Who Saw Lions By Berlie Doherty|****|Rs.300|****",
  "The Heirs Of The Prophet Muhammad By Barnaby Rogerson|199.Rs|Rs.300|****",
  "Love Letter Dead By Ava Dellaira|****|Rs.300|Rs.500",
  "A Temporary Gift By Asmaa Hussein|199.Rs|Rs.300|****",
  "The Hound Of The Baskervilles By Sir Arthur Conan Doyle|199.Rs|Rs.300|****",
  "The Shell Collector By Anthony Doerr|****|Rs.300|****",
  "On The Come Up By Angie Thomas|****|Rs.300|****",
  "The Color Of Our Sky By Amita Trasi|****|Rs.300|****",
  "Speeches That Changed The World By Alan J Whiticker|****|Rs.300|Rs.500",
  "Reclaim Your Heart By Yasmin Mogahed|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - Esio Trot|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - Charlie And The Great Glass Elevator|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - The BFG|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - The Giraffe And The Pelly And Me|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - Danny The Champion Of The World|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - Fantastic Mr.Fox|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - Matilda|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - The Magic Finger|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - The Twits|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - The Witches|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - George's Marvellous Medicine|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - James and the Giant Peach|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - Boy: Tales Of Childhood|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - Going Solo|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - The Enormous Crocodile|199.Rs|Rs.300|Rs.500",
  "Roald Dahl - Charlie And The Chocolate Factory|199.Rs|Rs.300|Rs.500",
  "Harry Potter - The Cursed Child (Parts One And Two) By J.k. Rowling|249.Rs|Rs.350|Rs.550",
  "Harry Potter - The Deathly Hallows By J.k. Rowling|249.Rs|Rs.350|Rs.550",
  "Harry Potter - The Half-blood Prince By J.k. Rowling|249.Rs|Rs.350|Rs.550",
  "Harry Potter - The Order Of The Phoenix By J.k. Rowling|249.Rs|Rs.350|Rs.550",
  "Harry Potter - The Goblet Of Fire By J.k. Rowling|249.Rs|Rs.350|Rs.550",
  "Harry Potter - The Prisoner Of Azkaban By J.k. Rowling|249.Rs|Rs.350|Rs.550",
  "Harry Potter - The Chamber Of Secrets By J.k. Rowling|249.Rs|Rs.350|Rs.550",
  "Harry Potter - The Philosopher's Stone By J.k. Rowling|249.Rs|Rs.350|Rs.550",
  "The 48 Laws Of Power By Robert Greene|199.Rs|Rs.300|Rs.500",
  "Cold War In The Islamic World By Dilip Hiro|****|Rs.300|****",
  "Elon Musk By Ashlee Vance|****|Rs.300|****",
  "Red Queen By Victoria Aveyard|199.Rs|Rs.300|****",
  "The Zahir By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "Freakonomics By Steven D Levitt|199.Rs|Rs.300|****",
  "The Magic Of Thinking Big By David J. Schwartz|199.Rs|Rs.300|Rs.500",
  "The Road To Mecca - in Urdu|199.Rs|Rs.300|Rs.500",
  "The 7 Habits Of Highly Effective People By Stephen R. Covey|199.Rs|Rs.300|Rs.500",
  "Girl Online On Tour By Zoe Sugg|****|****|****",
  "In The Place Of Fallen Leaves By Tim Pears|****|****|****",
  "Nice Work If You Can Get It By Celia Imrie|****|****|****",
  "Letter To An Unknown Soldier By Kate Pullinger|****|****|****",
  "The Gannet's Gastronomic Miscellany By Killian Fox|****|****|****",
  "Treasure Island By Robert Louis Stevenson|199.Rs|****|****",
  "The Private Lives Of Pippa Lee By Rebecca Miller|****|****|****",
  "We Come Apart By Sarah Crossan|****|****|****",
  "Fifty Shades Of Grey By El James|199.Rs|****|Rs.500",
  "Horrid Henry - Tricks The Tooth Fairy By Francesca Simon|199.Rs|****|****",
  "The Cryptid Files: Loch Nessby Jean Flitcroft|****|****|****",
  "Black Tide by Peter Temple|****|****|****",
  "The Iron Necklace by Giles Waterfield|****|****|****",
  "Out Of The Ice By Ann Turner|****|****|****",
  "Take Me On By Katie Mcgarry|****|****|****",
  "CEO Of Earth By Simon Hammond|****|****|****",
  "The Social Executive By Dionne Kasian-lew|****|****|****",
  "The New Edge In Knowledge: How Knowledge Management Is Changing The Way We Do Business By Carla O'dell|****|****|****",
  "The Naked CEO By Alex Malley|****|****|****",
  "Imagine: Start A Revolution In Your Life And Business By Jeffrey Slayter|****|****|****",
  "31 Days To Millionaire Marketing Miracles By Tracy Repchuk|****|Rs.300|****",
  "Social Policy Review 25: Analysis And Debate In Social Policy, 2013|****|Rs.300|****",
  "To The Lighthouse By Virginia Woolf|199.Rs|****|****",
  "The Blue Between Sky And Water By Susan Abulhawa|****|****|****",
  "Remember By Barbara Taylor Bradford|****|****|****",
  "The Scorch Trials By James Dashner|199.Rs|****|****",
  "Snap Revision Unseen Poetry By Collins GCSE|****|****|****",
  "A Little Princess By Frances Hodgson Burnett|199.Rs|****|****",
  "Girls' Miscellany By Lottie Stride|****|****|****",
  "Tales Of A Tiller Girl By Irene Holland|****|****|****",
  "The Tempest: A Bowers And Hunter Mystery By James Lilliefors|199.Rs|****|****",
  "Crash By Sean Williams|****|****|****",
  "The Art Of Grace By Sarah L. Kaufman|****|****|****",
  "The Mime Order By Samantha Shannon|****|****|****",
  "The Last Quarter By Martin Flanagan|****|****|****",
  "F*** My Life By Maxime Valette, Guillaume Passaglia, Didier Guedj|****|****|****",
  "Harbour By Paul House|****|****|****",
  "Wife On The Run By Fiona Higgins|****|****|****",
  "My Notorious Life By Kate Manning|****|****|****",
  "A Dangerous Crossing By Rachel Rhys|****|****|****",
  "Lord Of Shadows The Dark Artifices By Cassandra Clare|199.Rs|****|****",
  "Rather His Own Man By Geoffrey Robertson|****|****|****",
  "Jugaad Innovation By Radjou Prabhu Ahuja|****|****|Rs.500",
  "Reinventing The Entrepreneur: Turning Your Dream Business Into A Reality|****|****|Rs.500",
  "Brand Advocates: Turning Enthusiastic Customers Into A Powerful Marketing Force By Fuggetta, Rob|****|****|Rs.500",
  "Change Lesson From The Ceo By Coetsee Flood|****|****|Rs.500",
  "Novel Characters: A Genealogy By Maria Dibattista|****|****|Rs.500",
  "Here's The Pitch: How To Pitch Your Business To Anyone, Get Funded, And Win Clients By Martin Soorjoo|****|****|Rs.500",
  "Unselling The New Customer Experience By Stratten Kramer|****|****|Rs.500",
  "Human Resource Management: Strategies And Processes. Sixth Edition By Alan R. Nankervis|****|****|Rs.500",
  "The Warren Buffett Way Workbook By Robert G. Hagstrom|****|****|Rs.500",
  "The Plugged-in Manager: Get In Tune With Your People, Technology, And Organization To Thrive By Terri L. Griffith|****|****|Rs.500",
  "A Positive Revolution By Edward De Bono|****|Rs.300|Rs.500",
  "Conflicts: A Better Way To Resolve Them By Edward De Bono|199.Rs|Rs.300|Rs.500",
  "Uncommon Type By Tom Hanks|199.Rs|****|Rs.500",
  "Brave New World By Aldous Huxley|****|****|Rs.500",
  "Wonder By Rj Palacio|****|Rs.300|Rs.500",
  "Orphan Monster Spy By Matt Killeen|****|Rs.300|Rs.500",
  "Eleanor Oliphant Is Completely Fine By Gail Honeyman|****|Rs.300|Rs.500",
  "Fire And Fury By Michael Wolff|****|Rs.300|Rs.500",
  "The Other Side Of Truth By Beverley Naidoo|199.Rs|Rs.300|Rs.500",
  "Percy Jackson And The Lightning By Rick Riordan|199.Rs|Rs.300|Rs.500",
  "Animal Farm By George Orwell|199.Rs|Rs.300|Rs.500",
  "The Twitter Diaries By Georgie Thompson, Imogen Lloyd Webber|****|Rs.300|Rs.500",
  "The Hate U Give By Angie Thomas|199.Rs|Rs.300|Rs.500",
  "A Wrinkle In Time By Madeleine L'engle|****|Rs.300|Rs.500",
  "The Five People You Meet In Heaven By Mitch Albom|199.Rs|Rs.300|Rs.500",
  "The Diary Of A Voung Girl By Anne Frank|199.Rs|Rs.300|Rs.500",
  "The Time Keeper By Mitch Albom|199.Rs|Rs.300|Rs.500",
  "The Perks Of Being A Wallflower By Stephen Chbosky|****|Rs.300|Rs.500",
  "By The River Piedra I Sat Down And Wept By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "Aleph By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "The Giver By Lois Lowry|****|Rs.300|Rs.500",
  "Hippie By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "The Pilgrimage By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "Manuscript Found In Accra By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "Out Of The Maze By Spencer Johnson|199.Rs|Rs.300|Rs.500",
  "Who Moved My Cheese By Spencer Johnson|****|Rs.300|Rs.500",
  "The Book Of Love By Fionnuala Kearney|199.Rs|Rs.300|Rs.500",
  "You Can Win By Shiv Khera|199.Rs|Rs.300|Rs.500",
  "The Runaways By Fatima Bhutto|****|Rs.300|Rs.500",
  "Permanent Record By Edward Snowden|199.Rs|Rs.300|Rs.500",
  "Gernghis Khan By John Man|199.Rs|Rs.300|Rs.500",
  "Working With Emotional Intelligence By Daniel Goleman|199.Rs|Rs.300|Rs.500",
  "Everything I Never Told You By Celeste Ng|199.Rs|Rs.300|Rs.500",
  "That Thing We Call A Heart By Sheba Karim|199.Rs|Rs.300|Rs.500",
  "God By Reza Aslan|199.Rs|Rs.300|Rs.500",
  "The Moonlight Dreamers By Siobhan Curham|****|Rs.300|Rs.500",
  "Me Before You By Jojo Moyes|199.Rs|Rs.300|Rs.500",
  "Undying Affinity By Sara Naveed|199.Rs|Rs.300|Rs.500",
  "99 Percent Mine By Sally Thorne|****|Rs.300|Rs.500",
  "The Hating Game By Sally Thorne|****|Rs.300|Rs.500",
  "All Your Perfects By Colleen Hoover|199.Rs|Rs.300|Rs.500",
  "November 9 By Colleen Hoover|199.Rs|Rs.300|Rs.500",
  "Hopeless By Colleen Hoover|199.Rs|Rs.300|Rs.500",
  "A Short History Of The World By Christopher Lascelles|199.Rs|Rs.300|Rs.500",
  "Saladin By John Man|199.Rs|Rs.300|Rs.500",
  "Becoming By Michelle Obama|199.Rs|Rs.300|Rs.500",
  "The Secret By Rhonda Byrnes|199.Rs|Rs.300|Rs.500",
  "Games People Play By Eric Berne|****|Rs.300|Rs.500",
  "Living With Honour By Shiv Khera|****|Rs.300|Rs.500",
  "The Spy By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "The Valkyries By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "Adultery By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "How To Have Creative Ideas By Edward De Bono|199.Rs|Rs.300|Rs.500",
  "5 Levels Of Leadership By John Maxwell|199.Rs|Rs.300|Rs.500",
  "Drive By Daniel Pink|****|Rs.300|Rs.500",
  "The 8 In Habits Of Highly Effective People By Stephen R.Covey|199.Rs|Rs.300|Rs.500",
  "The Last Lecture By Randy Pausch|199.Rs|Rs.300|Rs.500",
  "Reflection Of A Man II By Amari Soul|199.Rs|Rs.300|Rs.500",
  "The Power Of Now By Eckhart Tolle|199.Rs|Rs.300|Rs.500",
  "Out Of Egypt By Andre Aciman|****|Rs.300|Rs.500",
  "A Very Large Expanse Of Sea By Tahereh Mafi|****|Rs.300|Rs.500",
  "The One Thing By Gary W. Keller, Jay Papasan|****|Rs.300|Rs.500",
  "Man's Search For Ultimate Meaning By Viktor Frankl|199.Rs|Rs.300|Rs.500",
  "My Feudal Lord By Tehmina Durrani|****|Rs.300|Rs.500",
  "Good Manners For Nice People' By Amy Alkon|****|Rs.300|Rs.500",
  "Models By Mark Manson|199.Rs|Rs.300|Rs.500",
  "In A Kingdom By The Sea By Sara Macdonald|****|Rs.300|Rs.500",
  "What's A Girl Gotta Do? Holly Bourne|****|Rs.300|Rs.500",
  "How Do You Like Me Now? Holly Bourne|****|Rs.300|Rs.500",
  "The Manifesto On How To Be Interesting By Holly Bourne|199.Rs|Rs.300|Rs.500",
  "The Art Of War By Sun-Tzu|199.Rs|Rs.300|Rs.500",
  "1984 George Orwell|199.Rs|Rs.300|Rs.500",
  "This Is Going To Hurt By Adam Kay|199.Rs|Rs.300|Rs.500",
  "The Place I've Cried In Public By Holly Bourne|199.Rs|Rs.300|Rs.500",
  "Francis Fukuyama: Identity|****|Rs.300|Rs.500",
  "12 Rules For Life By Jordan Peterson|199.Rs|Rs.300|Rs.500",
  "Mindset By Carol dweck,Ph.D.|199.Rs|Rs.300|Rs.500",
  "Men Are From Mars Women Are From Venus By John Gray|199.Rs|Rs.300|Rs.500",
  "The 80/20 Principle Book By Richard Koch|199.Rs|Rs.300|Rs.500",
  "13 Things Mentally Strong People Don�t Do By Amy Morin|199.Rs|Rs.300|Rs.500",
  "The Innovators Walter Isaacson By Walter Isaacson|****|Rs.300|Rs.500",
  "The Power Of Focus By Jack Canfield|199.Rs|Rs.300|Rs.500",
  "Two A Novel By Gulzar|199.Rs|Rs.300|Rs.500",
  "Empire Of Storms By Sarah J. Maas|****|Rs.300|Rs.500",
  "Crown Of Midnight By Sarah J. Maas|****|Rs.300|Rs.500",
  "The Assassin's Blade By Sarah J. Maas|****|Rs.300|Rs.500",
  "Court Of Wings And Ruin By Sarah J Maas|****|Rs.300|Rs.500",
  "heir of fire by sarah j maas|****|Rs.300|Rs.500",
  "Bridge Of Clay By Markus Zusak|****|Rs.300|Rs.500",
  "My Sister The Serial Killer By Oyinkan Braithwaite|****|Rs.300|Rs.500",
  "The Godfather By Mario Puzo|****|Rs.300|Rs.500",
  "Manto Saheb: Friends And Enemies On The Great Maverick|199.Rs|Rs.300|Rs.500",
  "Selected Short Stories Translated By Aatish Taseer By Saadat Hasan Manto|****|Rs.300|Rs.500",
  "The Hitchhiker's Guide To The Galaxy By Douglas Adams|****|Rs.300|Rs.500",
  "I Am Pilgrim By Terry Hayes|****|Rs.300|Rs.500",
  "Our Stary And Heart Here Sara Naveed|****|Rs.300|Rs.500",
  "Things Fall Apart By Chinua Achebe|****|Rs.300|Rs.500",
  "Bitter Fruit The Very Best Of By Saadat Hasan Manto|****|Rs.300|Rs.500",
  "Five Feet Apart Book By Rachael Lippincott|199.Rs|Rs.300|Rs.500",
  "Never Never Tarryn Fisher By Colleen Hoover|****|Rs.300|Rs.500",
  "The Testaments By Margaret Atwood|****|Rs.300|Rs.500",
  "This Girl By Colleen Hoover|199.Rs|Rs.300|Rs.500",
  "Throne Of Glass By Sarah J. Maas|199.Rs|Rs.300|Rs.500",
  "Tower Of Dawn By Sarah J Maas|****|Rs.300|Rs.500",
  "long walk to freedom by nelson mandela|****|Rs.300|Rs.500",
  "Mein Kampf By Adolf Hitler|****|Rs.300|Rs.500",
  "Emil Ludwig By Napoleon|****|Rs.300|Rs.500",
  "The Nectar Of Pain Najwa Zebian|199.Rs|Rs.300|Rs.500",
  "Sparks Of Phoenix By Najwa Zebian|199.Rs|Rs.300|Rs.500",
  "Him By Pierre Jeanty|199.Rs|Rs.300|Rs.500",
  "Her: By Pierre Jeanty|199.Rs|Rs.300|Rs.500",
  "The New Silk Road By Peter Frankopan|199.Rs|Rs.300|Rs.500",
  "Brain Rules By John Medina|199.Rs|Rs.300|Rs.500",
  "Homo Deus: A Brief History Of Tomorrow By Yuval Noah Harari|199.Rs|Rs.300|Rs.500",
  "The Eyes Of Darkness By Dean Koontz|****|Rs.300|Rs.500",
  "The Girl On The Train By Paula Hawkins|199.Rs|Rs.300|Rs.500",
  "Pleasures Of Philosophy By Will Durant|****|Rs.300|Rs.500",
  "Heroes Of History By Will Durant|****|Rs.300|Rs.500",
  "Optimism Over Despair By Noam Chomsky|199.Rs|Rs.300|Rs.500",
  "On Anarchism By Noam Chomsky|199.Rs|Rs.300|Rs.500",
  "Power System By Noam Chomsky|199.Rs|Rs.300|Rs.500",
  "Failed States By Noam Chomsky|199.Rs|Rs.300|Rs.500",
  "Hope And Prosperity By Noam Chomsky|199.Rs|Rs.300|Rs.500",
  "Deception Point By Dan Brown|199.Rs|Rs.300|Rs.500",
  "The Sky Is Everywhere Jandy Nelson|****|Rs.300|Rs.500",
  "Her Vol 2 Pierre Jeanty|199.Rs|Rs.300|Rs.500",
  "Yesterday I Was The Moon By Noor Unnahar|****|Rs.300|Rs.500",
  "Whiskey Words & A Shovel Volume 1|****|Rs.300|Rs.500",
  "An Abundance Of Katherines By John Green|199.Rs|Rs.300|Rs.500",
  "Paper Towns By John Green|199.Rs|Rs.300|Rs.500",
  "Nicholas Sparks By Every Breath|199.Rs|Rs.300|Rs.500",
  "The Fault In Our Stars By John Green|199.Rs|Rs.300|Rs.500",
  "Wuthering Heights By Emily Bronte|199.Rs|Rs.300|Rs.500",
  "The Magic By Rhonda Byrne|199.Rs|Rs.300|Rs.500",
  "The Power By Rhonda Byrne|****|Rs.300|Rs.500",
  "Hero By Rhonda Byrne|****|Rs.300|Rs.500",
  "Why Men Love Bitches By Sherry Argov|****|Rs.300|Rs.500",
  "100 Things Millionaires Do By Nigel Cumberland|199.Rs|Rs.300|Rs.500",
  "Discover Your Treasure By Robert A Fiacco|****|Rs.300|Rs.500",
  "The 10x Rule By Grant Cardone|199.Rs|Rs.300|Rs.500",
  "Future Of Humanity By Michio Kaku|199.Rs|Rs.300|Rs.500",
  "Unlocking The Universe By Stephen Hawking|199.Rs|Rs.300|Rs.500",
  "Stephen Hawking Brief Answers To The Big Questions|199.Rs|Rs.300|Rs.500",
  "The Monk Who Sold His Ferrari By Robin Sharma|199.Rs|Rs.300|Rs.500",
  "The 5 Am Club By Robin Sharma|199.Rs|Rs.300|Rs.500",
  "The Little Black Book For Stunning Success By Robin Sharma|199.Rs|Rs.300|Rs.500",
  "Success Habits By Napoleon Hill|199.Rs|Rs.300|Rs.500",
  "You Can Sell By Shiv Khera|****|Rs.300|Rs.500",
  "Philosophical Essays By Bertrand Russell|199.Rs|Rs.300|Rs.500",
  "After By Anna Todd|****|Rs.300|Rs.500",
  "Smarter Faster Better The Secrets Of Being Productive In Life And Business By Charles Duhigg|199.Rs|Rs.300|Rs.500",
  "10 Entrepreneur By Patrick J. Mc Ginnis|199.Rs|Rs.300|Rs.500",
  "Smart Guide For Awesome Memory By Shireen Stephen|****|Rs.300|Rs.500",
  "The Tata Saga: Timeless Stories From India�s Largest Business Group By Harish Bhat|****|Rs.300|Rs.500",
  "How To Change Things When Change Is Hard By Chip And Dan|****|Rs.300|Rs.500",
  "The Greatest Mind And Ideas Of All Time By Will Durant|199.Rs|Rs.300|Rs.500",
  "Bad Boys By David Wallians|****|Rs.300|Rs.500",
  "Mrs Funnybones By Twinkle Khanna|199.Rs|Rs.300|Rs.500",
  "Pyjamas Are Forgiving By Twinkle Khanna|199.Rs|Rs.300|Rs.500",
  "Mind Platter By Najwa Zebian|199.Rs|Rs.300|Rs.500",
  "Alibaba By Duncan Clark|199.Rs|Rs.300|Rs.500",
  "Outliers By Malcolm Gladwell|199.Rs|Rs.300|Rs.500",
  "Blink By Malcolm Gladwell|199.Rs|Rs.300|Rs.500",
  "Broker Verses By Kamila Shamsie|****|Rs.300|Rs.500",
  "All The Light We Cannot See By Anthony Doerr|199.Rs|Rs.300|Rs.500",
  "Love Hafe & Other Filters By Samira Ahmed|****|Rs.300|Rs.500",
  "Our Lady Of Alice Bhatti By Mohammed Hanif|199.Rs|Rs.300|Rs.500",
  "Koi Good News By Zarreen Khan|****|Rs.300|Rs.500",
  "High Performance Habits By Brendon Burchard|199.Rs|Rs.300|Rs.500",
  "21 Lessons For The 21st Century By Yuval Noah Harari|****|Rs.300|Rs.500",
  "100 Things Successful People Do By Nigel Cumberland|199.Rs|Rs.300|Rs.500",
  "The 4-hour Work Week By Tim Ferriss|199.Rs|Rs.300|Rs.500",
  "Coaching For Performance By Sir John Whitmore|199.Rs|Rs.300|Rs.500",
  "Silent Patient By Alex Michaelides|****|Rs.300|Rs.500",
  "To Kill A Mockingbird By Harper Lee|199.Rs|Rs.300|Rs.500",
  "Social Media Success For Every Brand By Claire Diaz-ortiz|199.Rs|Rs.300|Rs.500",
  "Rework Change The Way You Work Forever|****|Rs.300|Rs.500",
  "When The Scientific Secrets Of Perfect Timing By Daniel H. Pink|****|Rs.300|Rs.500",
  "Mindfulness On The Run By Dr Chantal Hofstee|199.Rs|Rs.300|Rs.500",
  "Microsoft Secrets By Dave Jaworski|199.Rs|Rs.300|Rs.500",
  "Pakistan Under Siege Extremism Society And The State By Madiha Afzal|199.Rs|Rs.300|Rs.500",
  "Pakistan At The Crossroads By Christophe Jaffrelot|****|Rs.300|Rs.500",
  "Pakistan Courting The Abyss By Tilak Devasher|****|Rs.300|Rs.500",
  "Pakistan At The Helm By Tilak Devasher|199.Rs|Rs.300|Rs.500",
  "The China Pakistan Axis By Andrew Small|199.Rs|Rs.300|Rs.500",
  "The Pakistan Paradox Book By Christophe Jaffrelot|199.Rs|Rs.300|Rs.500",
  "The Last Mughal By William Dalrymple|****|Rs.300|Rs.500",
  "The Rise And Fall Of The Great Powers By Paul Kennedy|****|Rs.300|Rs.500",
  "The Life And The Legend Of The Sultan Saladin By Jonathan Phillips|****|Rs.300|Rs.500",
  "Unf*ck Yourself By Gray Jhon Bishop|199.Rs|Rs.300|Rs.500",
  "Unf*ckology By Amy Alkon|****|Rs.300|Rs.500",
  "The Book Of Rumi 105 Stories And Fables|199.Rs|Rs.300|Rs.500",
  "Rumi's Daughter By Muriel Maufroy|199.Rs|Rs.300|Rs.500",
  "Teaching Of Rumi By Andrew Harvey|199.Rs|Rs.300|Rs.500",
  "Zuleikha A Novel Guzel Yakhina|199.Rs|Rs.300|Rs.500",
  "Talking To Strangers By Malcolm Gladwell|199.Rs|Rs.300|Rs.500",
  "When Breath Becomes Air By Paul Kalanithi|199.Rs|Rs.300|Rs.500",
  "A Thousand Splendid Suns By Khaled Hosseini|199.Rs|Rs.300|Rs.500",
  "And The Mountains Echoed By Khaled Hosseini|199.Rs|Rs.300|Rs.500",
  "May Be Not By Colleen Hoover|199.Rs|Rs.300|Rs.500",
  "Ugly Love By Colleen Hoover|199.Rs|Rs.300|Rs.500",
  "May Be Someday By Colleen Hoover|199.Rs|Rs.300|Rs.500",
  "Points Of Retreat By Colleen Hoover|199.Rs|Rs.300|Rs.500",
  "Without Merit By Colleen Hoover|199.Rs|Rs.300|Rs.500",
  "Rumi's Secret The Life Of The Sufi Poet Of Love By Brad Gooch|199.Rs|Rs.300|Rs.500",
  "The Soul Of Rumi By Coleman Barks|199.Rs|Rs.300|Rs.500",
  "Sapiens: A Brief History of Humankind By Yuval Noah Harari|199.Rs|Rs.300|Rs.500",
  "The Summer Of Impossible Things By Rowan Coleman|199.Rs|Rs.300|Rs.500",
  "The Alchemist By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "Eleven Minutes By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "Veronika Decides To Die By Paulo Coelho|199.Rs|Rs.300|Rs.500",
  "A Gentleman In Moscow By Amor Towles|199.Rs|Rs.300|Rs.500",
  "The Marriage Clock By Zara Raheem|199.Rs|Rs.300|Rs.500",
  "This Book Will Change Your Mind About Mental Health By Nathan Filer|199.Rs|Rs.300|Rs.500",
  "Dare Not Linger By Nelson Mandela|****|Rs.300|Rs.500",
  "Leonardo Da Vinci By Walter Isaacson|****|Rs.300|Rs.500",
  "The Time Machine By H.g. Wells|199.Rs|Rs.300|Rs.500",
  "The Old Man And The Sea By Vintage Hemingway|199.Rs|Rs.300|Rs.500",
  "Call Me By Your Name By Andr� Aciman|****|Rs.300|Rs.500",
  "Top Dancing To Work By Warren Buffett|****|Rs.300|Rs.500",
  "Ground Rules By Warren Buffett's|199.Rs|Rs.300|Rs.500",
  "The Bell Jar By Sylvia Plath|****|Rs.300|Rs.500",
  "Business Adventures By John Brook|199.Rs|Rs.300|Rs.500",
  "It Only Happnes In The Movie By Holly Bourne|****|Rs.300|Rs.500",
  "Are We All Lemmings & Snowflakes? By Holly Bourne|****|Rs.300|Rs.500",
  "How Hard Can Love Be By Holly Bourne|199.Rs|Rs.300|Rs.500",
  "A Real Girl's Guide To Money By Effie Zahos|****|Rs.300|Rs.500",
  "The Fall Of The House Of Wilde By Emer O' Sullivan|****|Rs.300|Rs.500",
  "Diary Of A Wimpy Kid By Jeff Kinney|199.Rs|Rs.300|Rs.500",
  "The Grand Design By Stephen Hawking|****|Rs.300|Rs.500",
  "The Anarchy By William Dalrymple|199.Rs|Rs.300|Rs.500",
  "Happy By Fearne Cotton|****|Rs.300|Rs.500",
  "Influence The Psychology Of Persuasion By Robert B. Cialdini|199.Rs|Rs.300|Rs.500",
  "Befearless By Jean Case|199.Rs|Rs.300|Rs.500",
  "The Happiness Advantage By Shawn Achor|199.Rs|Rs.300|Rs.500",
  "To The Women I Once Loved By Pierre Alex Jeanty|199.Rs|Rs.300|Rs.500",
  "The Greatest Salesman In The World By Og Mandino|****|Rs.300|Rs.500",
  "2020 World Of War By Paul Cornish|****|Rs.300|Rs.500",
  "Focus The Hidden Driver Of Excellence By Daniel Goleman|199.Rs|Rs.300|Rs.500",
  "The Great Gatsby By F. Scott Fitzgerald|199.Rs|Rs.300|Rs.500",
  "The 80 Minute MBA by John Knell & Richard Reeves|199.Rs|Rs.300|Rs.500",
  "The Dancing Girl Of Lahore By Louise Brown|****|Rs.300|Rs.500",
  "Salem Falls By Jodi Picoult|****|Rs.300|Rs.500",
  "Tippoo Sultan By Colonel Meadows Taylor|199.Rs|Rs.300|Rs.500",
  "The Autobiography Of Malcolm X By Alex Haley|199.Rs|Rs.300|Rs.500",
  "The Girl In The Ice By Robert Bryndza|199.Rs|Rs.300|Rs.500",
  "Girl, Woman, Other. By Bernardine Evaristo|199.Rs|Rs.300|Rs.500",
  "The Ministry Of Utmost Happiness By Arundhati Roy|199.Rs|Rs.300|Rs.500",
  "The Wish Granter By C.J Redwine|****|Rs.300|Rs.500",
  "Attachment By Rainbow Rowell|199.Rs|Rs.300|Rs.500",
  "Eleanor & Park By Rainbow Rowell|199.Rs|Rs.300|Rs.500",
  "Big Magic By Elizabeth Gilbert|199.Rs|Rs.300|Rs.500",
  "Paradise Tower Book Shweta Bachchan-nanda|****|Rs.300|Rs.500",
  "Looking For Alaska By John Green|199.Rs|Rs.300|Rs.500",
  "Turtles All The Way Down By John Green|199.Rs|Rs.300|Rs.500",
  "The Ice By Laline Paull|****|Rs.300|Rs.500",
  "Where The Crawdads Sing By Delia Owens|****|Rs.300|Rs.500",
  "The God Of Small Things By Arundhati Roy|199.Rs|Rs.300|Rs.500",
  "Will Grayson Will Grayson By John Green And David Levithan|199.Rs|Rs.300|Rs.500",
  "Honour By Elif Shafak|199.Rs|Rs.300|Rs.500",
  "Holding Up The Universe By Jennifer Niven|199.Rs|Rs.300|Rs.500",
  "Milkman By Anna Burns|199.Rs|Rs.300|Rs.500",
  "The Sun Is Also A Star By Nicola Yoon|199.Rs|Rs.300|Rs.500",
  "Field Notes On Love By Jennifer E. Smith|****|Rs.300|Rs.500",
  "To All The Boys I've Loved Before By Jenny Han|199.Rs|Rs.300|Rs.500",
  "Wayward Son By Rainbow Rowell|****|Rs.300|Rs.500",
  "Men Without Women By Haruki Murakami|199.Rs|Rs.300|Rs.500",
  "Love In The Time Of Cholera By Gabriel Garcia Marquez|199.Rs|Rs.300|Rs.500",
  "Exit West By Mohsin Hamid|199.Rs|Rs.300|Rs.500",
  "All The Bright Places By Jennifer Niven|****|Rs.300|Rs.500",
  "Moth Smoke By Mohsin Hamid|199.Rs|Rs.300|Rs.500",
  "A Firefly In The Dark By Shazaf Fatima Haider|****|Rs.300|Rs.500",
  "The Flea Palace By Elif Shafak|199.Rs|Rs.300|Rs.500",
  "The Architect's Apprentice By Elif Shafak|199.Rs|Rs.300|Rs.500",
  "The Gaze By Elif Shafak|199.Rs|Rs.300|Rs.500",
  "Black Milk By Elif Shafak|199.Rs|Rs.300|Rs.500",
  "Three Daughters Of Eve By Elif Shafak|199.Rs|Rs.300|Rs.500",
  "The Book Of Why By Judea Pearl|199.Rs|Rs.300|Rs.500",
  "The Art Of Thinking Clearly By Rolf Dobelli|199.Rs|Rs.300|Rs.500",
  "Lean In By Sheryl Sandberg|****|Rs.300|Rs.500",
  "Let It Snow By John Green|199.Rs|Rs.300|Rs.500",
  "The Latte Factor By David Bach|****|Rs.300|Rs.500",
  "50 Success Classics By Tom Butler-Bowdon|****|Rs.300|Rs.500",
  "Tap Dancing To Work By Carol Loomis|****|Rs.300|Rs.500",
  "How To Think Like A Ceo: The 22 Vital Traits You Need To Be The Person At The Top By Debra A Benton|199.Rs|Rs.300|Rs.500",
  "The Art Of Good Life By Rolf Dobelli|199.Rs|Rs.300|Rs.500",
  "The Self-made Billionaire Effect: How Extreme Producers Create Massive Value By Mitch Cohen, John Sviokla|199.Rs|Rs.300|Rs.500",
  "Knight Shoe Dog By Simon & Schuster|199.Rs|Rs.300|Rs.500",
  "Millionaires Success Habits By Dean Graziosi|199.Rs|Rs.300|Rs.500",
  "Become An Effective Leader By Dale Carnegie|****|Rs.300|Rs.500",
  "How To Enjoy Your Life And Your Job By Dale Carnegie|199.Rs|Rs.300|Rs.500",
  "The Quick And Easy Way To Effective Speaking By Dale Carnegie|199.Rs|Rs.300|Rs.500",
  "Chicken Soup For The Soul By Jack Canfield|****|Rs.300|Rs.500",
  "The 7 Second Cv How To Land The Interview By James Reed|****|Rs.300|Rs.500",
  "Unshakeable By Tony Robbins|****|Rs.300|Rs.500",
  "Money Master The Game By Tony Robbins|199.Rs|Rs.300|Rs.500",
  "The 5 Love Languages By Gary Chapman|199.Rs|Rs.300|Rs.500",
  "Hyperfocus By Chris Bailey|199.Rs|Rs.300|Rs.500",
  "Winners Take All By Anand Giridharadas|****|Rs.300|Rs.500",
  "Blitzscaling By Reid Hoffman|****|Rs.300|Rs.500",
  "Managing In Turbulent Times By Peter F. Drucker|****|Rs.300|Rs.500",
  "Blue Ocean Shift|199.Rs|Rs.300|Rs.500",
  "The 4 Disciplines Of Execution By Chris Mcchesney|****|Rs.300|Rs.500",
  "The Emotionally Intelligent Leader By Daniel Goleman|199.Rs|Rs.300|Rs.500",
  "10 Rule For Strategic Innovators By Vijay Govindarajan And Chris Trimble|199.Rs|Rs.300|Rs.500",
  "The Leadership Of Teams By Mike Breant And Flona Elsa Dent|****|Rs.300|Rs.500",
  "Awaken The Giant Within By Tony Robbins|199.Rs|Rs.300|Rs.500",
  "Unlimited Power By Anthony Robbins|****|Rs.300|Rs.500",
  "Influencing Powerful People By Dirk Schlimm|****|Rs.300|Rs.500",
  "Find Your Why By Simon Sinek|199.Rs|Rs.300|Rs.500",
  "Steve Jobs Book By Walter Isaacson|199.Rs|Rs.300|Rs.500",
  "The Compound Effect By Darren Hardy|199.Rs|Rs.300|Rs.500",
  "Tools Of Titans By Tim Ferriss|199.Rs|Rs.300|Rs.500",
  "The Infinite Game By Simon Sinek|****|Rs.300|Rs.500",
  "The Leader In You By Dale Carnegie|199.Rs|Rs.300|Rs.500",
  "Who Says You Can't? You Do By Daniel Chidiac|199.Rs|Rs.300|Rs.500",
  "The World's 100 Great Speeches By Terry O'Brien|****|Rs.300|Rs.500",
  "The Startup Why By Eric Ries|199.Rs|Rs.300|Rs.500",
  "ISIS The State Of Terror|****|Rs.300|Rs.500",
  "Unpopular Essays By Bertrand Russell|199.Rs|Rs.300|Rs.500",
  "The New Confessions Of An Economic Hit Man By John Perkins|199.Rs|Rs.300|Rs.500",
  "The Laws Of Human Nature Robert Greene|199.Rs|Rs.300|Rs.500",
  "Attitude Is Everything By Jeff Keller|199.Rs|Rs.300|Rs.500",
  "The Path To Personal Power By Napoleon Hill|****|Rs.300|Rs.500",
  "Road To Success By Napoleon Hill|199.Rs|Rs.300|Rs.500",
  "The Science Of Success By Napoleon Hill|199.Rs|Rs.300|Rs.500",
  "10 Success Secrets By Napoleon Hill|199.Rs|Rs.300|Rs.500",
  "Change Your Brain Change Your Life By Daniel Amen|****|Rs.300|Rs.500",
  "Winning Attitude By Jeff Keller|199.Rs|Rs.300|Rs.500",
  "Built To Last By Jim Collins|199.Rs|Rs.300|Rs.500",
  "Upheaval Turning Points For Nations In Crisis By Jared Diamond|****|Rs.300|Rs.500",
  "An Era Of Darkness By Shashi Tharoor|199.Rs|Rs.300|Rs.500",
  "Victory By Brian Tracy|199.Rs|Rs.300|Rs.500",
  "The Science Of Motivation By Brian Tracy|199.Rs|Rs.300|Rs.500",
  "Good To Great By Jim Collins|199.Rs|Rs.300|Rs.500",
  "A History Of Western Philosophy By Bertrand Russell|****|Rs.300|Rs.500",
  "The 100: A Ranking Of The Most Influential Persons In History By Michael Hart|199.Rs|Rs.300|Rs.500",
  "Guns Germs And Steel By Jared Diamond|****|Rs.300|Rs.500",
  "Zero To One By Peter Thiel|199.Rs|Rs.300|Rs.500",
  "Fact And Friction By Bertrand Russell|199.Rs|Rs.300|Rs.500",
  "An Outline Of Philosophy By Bertrand Russell|199.Rs|Rs.300|Rs.500",
  "The Conquest Of Happiness By Bertrand Russell|199.Rs|Rs.300|Rs.500",
  "Strengthsfinder 2.0 By Tom Rath|****|Rs.300|Rs.500",
  "How Women Rise: Break The 12 Habits Holding You Back By Sally Helgesen|199.Rs|Rs.300|Rs.500",
  "Ted Talks The Official Ted Guide To Public Speaking By Chris Anderson|199.Rs|Rs.300|Rs.500",
  "The Story Of Philosophy By Will Durant|199.Rs|Rs.300|Rs.500",
  "Fallen Leaves Last Word On Life, Love, War And God By Will Durant|****|Rs.300|Rs.500",
  "Pakistan: A Personal History By Imran Khan|199.Rs|Rs.300|Rs.500",
  "Dare To Lead By Brene Brown|199.Rs|Rs.300|Rs.500",
  "The Intelligence Trap By David Robson|****|Rs.300|Rs.500",
  "The Lessons Of History By Will & Ariel Durant|199.Rs|Rs.300|Rs.500",
  "The Intelligent Investor By Benjamin Graham|199.Rs|Rs.300|Rs.500",
  "The Evolution Of Everything By Matt Ridley|****|Rs.300|Rs.500",
  "A Brief History Of Everyone Who Ever Lived By Adam Rutherford|****|Rs.300|Rs.500",
  "Is Not Freedom Free By Shiv Khera|199.Rs|Rs.300|Rs.500",
  "The Teenage Brain By Frances Jensen|199.Rs|****|****",
  "11 Life Lesson From Nelson Mandela By Ndaba Mandela|199.Rs|Rs.300|Rs.500",
  "Leadership 2.0|199.Rs|Rs.300|Rs.500",
  "Emotional Intelligence 2.0|****|Rs.300|Rs.500",
  "A Brief History Of Time By Stephen Hawking From The Big Bang To Black Holes|199.Rs|Rs.300|Rs.500",
  "How To Stop Worrying And Start Living By Dale Carnegie|199.Rs|Rs.300|Rs.500",
  "The Leader Who Had No Title By Robin Sharma|199.Rs|Rs.300|Rs.500",
  "Discover Your Destiny By Robin Sharma|199.Rs|Rs.300|Rs.500",
  "Daily Inspiration By Robin Sharma|199.Rs|Rs.300|Rs.500",
  "Who Will Cry When You Die By Robin Sharma|199.Rs|Rs.300|Rs.500",
  "Making The Future By Noam Chomsky|199.Rs|Rs.300|****",
  "Global Discontents By Noam Chomsky|199.Rs|Rs.300|****",
  "Because We Say So By Noam Chomsky|199.Rs|Rs.300|****",
  "Who Rules The World By Noam Chomsky|199.Rs|Rs.300|Rs.500",
  "Hegemony Or Survival America�s Quest For Global Dominance By Noam Chomsky|199.Rs|Rs.300|Rs.500",
  "How The World Works By Noam Chomsky|199.Rs|Rs.300|Rs.500",
  "Tim Cook The Genius Who Took Apple To The Next Level By Leander Kahney|****|Rs.300|Rs.500",
  "What Young India Wants By Chetan Bhagat|****|Rs.300|Rs.500",
  "The Personal Mba By Josh Kaufman|199.Rs|Rs.300|Rs.500",
  "The First 20 Hours How To Learn Anything Fast Josh Kaufman|199.Rs|Rs.300|Rs.500",
  "Developing The Leader Within You By John C Maxwell|199.Rs|Rs.300|Rs.500",
  "You Can Achieve More Live By Design Not By Default By Shiv Khera|199.Rs|Rs.300|Rs.500",
  "How To Be A Better Leader By Stefan Stern|199.Rs|Rs.300|Rs.500",
  "How To Negotiate By Christopher Cooper-ind|199.Rs|Rs.300|Rs.500",
  "In The Line Of Fire By Pervez Musharraf|199.Rs|Rs.300|Rs.500",
  "Benazir Bhutto Daughter Of The East|****|Rs.300|Rs.500",
  "Diplomacy By Henry Kissinger|****|Rs.300|Rs.500",
  "Why Nation Fails The Origins Of Power, Prosperity And Poverty|****|Rs.300|Rs.500",
  "Ghost Wars By Steve Coll|****|Rs.300|Rs.500",
  "The Clash Of Civilizations By Samuel P. Huntington|199.Rs|Rs.300|Rs.500",
  "How To Lose A Country By Ece Temelkuran|****|****|****",
  "The Spy Who Changed History By Svetlana Lokhova|****|****|****",
  "The First Nazi By Erich Ludendorff|****|****|****",
  "Secret Societies By Nick Harding|****|****|****",
  "Factfulness By Hans Rosling|199.Rs|Rs.300|****",
  "Man's Search For Meaning By Viktor e. Frankl|199.Rs|Rs.300|Rs.500",
  "Atomic Habits By James Clear|199.Rs|Rs.300|Rs.500",
  "Insane Mode Tesla Story By Hamish Mckenzie|199.Rs|Rs.300|****",
  "Fake By Robert Kiyosaki|****|Rs.300|Rs.500",
  "Good Habits Bad Habits By Wendy Wood|199.Rs|Rs.300|Rs.500",
  "How To Start A Business Without Any Money By Rachel Bridge|199.Rs|Rs.300|****",
  "The Science Of Likability By Patrick King|199.Rs|Rs.300|****",
  "Ego Is The Enemy By Ryan Holiday|199.Rs|Rs.300|****",
  "Being Pakistani By Raza Rumi|****|Rs.300|Rs.500",
  "How To Win Friends And Influence People By Dale Carnegie|199.Rs|Rs.300|Rs.500",
  "Khuda Bol Raha Hai By Abu Yahya (Original)|****|****|****",
  "Cash Flow Quadrant By Robert Kiyosaki|****|****|****",
  "Black Beauty Novel By Anna Sewell|199.Rs|Rs.300|****",
  "The Book Thief By Markus Zusak|199.Rs|Rs.300|Rs.500",
  "Get Art Of War By Sun Tzu|199.Rs|Rs.300|****",
  "The Kite Runner By Khaled Hosseini|199.Rs|Rs.300|Rs.500",
  "The 7 Habits Of Highly Effective Teens By Sean Covey|199.Rs|Rs.300|Rs.500",
  "Dozakhnama By Rabisankar Bal|199.Rs|Rs.300|Rs.500",
  "The Lean Startup By Eric Ries|199.Rs|Rs.300|Rs.500",
  "Sea Prayer By Khaled Hosseini|****|****|****",
  "How To Train Your Memory By Phil Chambers|199.Rs|Rs.300|Rs.500",
  "The Road To Mecca By Muhammad Asad|****|****|****",
  "Eat Pray Love By Elizabeth Gilbert.|199.Rs|Rs.300|Rs.500",
  "The Metamorphosis By Franz Kafka|199.Rs|Rs.300|Rs.500",
  "Sense And Sensibility By Jane Austen.|199.Rs|Rs.300|Rs.500",
  "It Ends With Us By Colleen Hoover|199.Rs|Rs.300|Rs.500",
  "Rumi Tales To Live By - By Kamla K. Kapur|199.Rs|Rs.300|Rs.500",
  "The Subtle Art Of Not Giving A F*ck By Mark Manson|199.Rs|Rs.300|Rs.500",
  "Everything Is F*cked A Book About Hope By Mark Manson|199.Rs|Rs.300|Rs.500",
  "The Spy And The Traitor By Ben Macintyre|****|****|****",
  "Pakistan A Hard Country By Anatol Lieven|****|Rs.300|Rs.600",
  "World Order By Henry Kissinger|****|Rs.300|Rs.500",
  "After The Prophet The Epic Story Of The Shia-sunni Split In Islam By Lesley Hazleton|****|****|****",
  "The Great Mughals And Their India By Dirk Collier|199.Rs|Rs.300|Rs.650",
  "Start With Why By Simon Sinek|199.Rs|Rs.300|****",
  "The 100$ Startup By Chris Guillebeau|199.Rs|Rs.300|Rs.500",
  "10 Minute 38 Seconds In This Strange World By Elif Shafak|199.Rs|Rs.300|Rs.500",
  "The Bastard Of Istanbul By Elif Shafak|199.Rs|Rs.300|Rs.500",
  "The Richest Man In Babylon By George S. Clason|199.Rs|Rs.300|Rs.500",
  "Leaders Eat Last By Simon Sinek|****|Rs.300|****",
  "The Forty Rules Of Love By Elif Shafak|199.Rs|Rs.300|Rs.500",
  "Eat That Frog By Brian Tracy|****|****|****",
  "Thinking Fast And Slow By Daniel Kahneman|199.Rs|Rs.300|Rs.500",
  "The Power Of Habit By Charles Duhigg|199.Rs|Rs.300|Rs.500",
  "Think And Grow Rich By Napoleon Hill|199.Rs|Rs.300|Rs.500",
  "How To Unleash The Power Of Your Subconscious Mind By Joseph Murphy|199.Rs|Rs.300|****",
  "Deep Work By Cal Newport|****|****|****",
  "The Power Of Your Subconscious Mind By Joseph Murphy|199.Rs|Rs.300|Rs.500",
  "Rich Dad Poor Dad By Robert Kiyosaki|199.Rs|Rs.300|Rs.500",
];

export const BOOK_PRINTING_COST_LIST = {
  'White page 70 grams B&W': 4,
  'White page 80 grams B&W': 4.5,
  'Yellow page 100 grams B&W': 4,
  'White page 70 grams Color': 5,
  'White page 80 grams Color': 6,
  'White page 100 grams Color': 7,
}
